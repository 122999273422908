import {
  CircularProgress,
  Select as MuiSelect,
  SelectProps,
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

interface MuiSelectProps extends SelectProps {
  loading?: boolean;
}

const Select = (props: MuiSelectProps) => {
  const { loading = false, inputProps, ...restProps } = props;

  return (
    <MuiSelect
      {...restProps}
      IconComponent={loading ? CircularProgressIcon : KeyboardArrowDownIcon}
      inputProps={{
        ...inputProps,
        'data-testid': props?.id || props?.name || 'select-dropdown',
      }}
    />
  );
};

const CircularProgressIcon = (props) => {
  return <CircularProgress size={20} style={{ marginRight: 6 }} {...props} />;
};

export default Select;
