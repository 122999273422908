import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

// Components
import { CommonDialog, TextField } from 'components';
import Dropdown from 'Views/FlexPayV2/common-components/Dropdown/Dropdown';

// Context
import { Context } from 'context';
import { UPDATE_INVOICE_FILTERS } from 'context/actions';

// Constants
import { PAGE_URLS } from 'Routes/Main/constants';
import { STATUS_LIST } from 'Views/FlexPayV2/invoices/constants';

// Types
import { FilterModalProps } from './types';

const FlexFilterModal = (props: FilterModalProps) => {
  const { isFilterOpen, closeFilterModal, isLoading } = props;

  const {
    state: { user, invoiceFilters, filterDataLoading },
    dispatch,
  } = useContext(Context);

  const { filterState, isFilterActive } = invoiceFilters;

  const [customFilter, setCustomFilter] = useState(_.cloneDeep(filterState));

  const dataLoading = Object.keys(filterDataLoading).some(
    (key) => filterDataLoading[key],
  );

  const { patient_id, first_name, last_name, invoice_no, email, status } =
    customFilter;

  const history = useHistory();

  const onFilterValueChange = (name: string, value: string | Date | number) =>
    setCustomFilter((previousFilter: typeof customFilter) => ({
      ...previousFilter,
      [name]: value,
    }));

  const emitFilterCloseEvent = (type: 'save' | 'reset') => {
    let updatedFilterState = _.cloneDeep(customFilter);

    if (type === 'reset') {
      const { region_id: rId, location_id: lId } =
        user?.settings?.level_data || {};

      updatedFilterState = {
        ...updatedFilterState,
        region_id: rId || '',
        location_id: lId || '',
        text: '',
        patient_id: '',
        first_name: '',
        last_name: '',
        email: '',
        status: '',
        invoice_no: '',
        created_start: null,
        created_end: null,
        dateLabel: '',
      };
    } else {
      closeFilterModal();
    }

    dispatch({
      type: UPDATE_INVOICE_FILTERS,
      payload: {
        filters: {
          filterState: updatedFilterState,
          pageProps: {
            page_number: 0,
            rowsPerPage: 100,
          },
          isFilterUpdated: true,
        },
      },
    });

    setCustomFilter(updatedFilterState);

    if (history.location.pathname !== PAGE_URLS.FLEX_PAY_INVOICES_LIST)
      history.push(PAGE_URLS.FLEX_PAY_INVOICES_LIST);
  };

  const renderTextField = (
    name: string,
    label: string,
    value: string | number | Date,
  ) => (
    <TextField
      fullWidth
      name={name}
      variant="outlined"
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onFilterValueChange(
          (event.target as HTMLInputElement).name,
          (event.target as HTMLInputElement).value,
        )
      }
      label={label}
      inputProps={{ 'data-testid': `filter-${name}` }}
    />
  );

  const isFormUnchanged = () => _.isEqual(filterState, customFilter);

  return (
    <CommonDialog
      className={isLoading ? 'disable-form' : ''}
      open={isFilterOpen}
      dialogTitle="Filter Invoices"
      primaryBtnTxt="Search"
      isLoading={isLoading}
      primaryButtonProps={{
        disabled: isFormUnchanged() || dataLoading,
      }}
      handlePrimaryButtonClick={() => emitFilterCloseEvent('save')}
      secondaryBtnTxt={isFilterActive ? 'Clear Filters' : ''}
      secondaryButtonProps={{ disabled: dataLoading }}
      handleSecondaryButtonClick={() => emitFilterCloseEvent('reset')}
      handleClose={closeFilterModal}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          {renderTextField('patient_id', 'Patient ID', patient_id)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('first_name', 'First Name', first_name)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('last_name', 'Last Name', last_name)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('invoice_no', 'Invoice Number', invoice_no)}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderTextField('email', 'Email Address', email)}
        </Grid>

        <Grid item xs={12} sm={4}>
          <Dropdown
            name="status"
            label="Status"
            value={status}
            list={STATUS_LIST}
            listName="label"
            onChange={onFilterValueChange}
            disabled={false}
            loading={false}
          />
        </Grid>
      </Grid>
    </CommonDialog>
  );
};

export default FlexFilterModal;
