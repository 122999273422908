import React from 'react';

import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

// Components
import Select from '../Mui/Select';

const Dropdown: React.FC<{
  name: string;
  label: string;
  value: string;
  required?: boolean;
  list: Array<{ [key: string]: any }>;
  listName: string;
  onChange: (name: string, value: string) => void;
  disabled: boolean;
  loading: boolean;
  defaultLabel?: string;
  defaultValue?: string;
}> = React.memo(
  ({
    name,
    label,
    value,
    required = false,
    list = [],
    listName,
    onChange,
    disabled,
    loading,
    defaultLabel = '',
    defaultValue = '',
  }) => (
    <FormControl
      required={required}
      fullWidth
      disabled={disabled}
      variant="outlined"
      className="m-r-16"
      style={{ minWidth: 200 }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        id={`filter-modal-select-${label}`}
        name={name}
        label={label ? `${label} ${required ? '*' : ''}` : undefined}
        value={value}
        loading={loading}
        onChange={(
          event: React.ChangeEvent<{ name?: string; value: unknown }>,
        ) =>
          onChange(event.target.name as string, event.target.value as string)
        }
      >
        {defaultLabel && (
          <MenuItem
            key={0}
            value={defaultValue}
            data-testid="filter-default-select-item"
          >
            {defaultLabel}
          </MenuItem>
        )}
        {list?.map((item, index) => (
          <MenuItem
            key={item[name]}
            value={item[name]}
            data-testid={`filter-${label.toLowerCase()}-select-item-${index}`}
          >
            {item[listName]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ),
);

export default Dropdown;
