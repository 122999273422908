import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import MUIDataTable from 'mui-datatables';
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  Button,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
  FormHelperText,
} from '@material-ui/core';
import {
  AddRounded as AddRoundedIcon,
  Clear as ClearIcon,
  Info as InfoIcon,
} from '@material-ui/icons';

import { SectionHeader, CheckBox } from 'components';
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';
import { PlansContext } from 'Views/Plans/context';
import {
  UPDATE_CATEGORY_DISCOUNTS,
  RESET_CATEGORY_DISCOUNTS,
  HIGHLIGHT_ERRORS,
} from 'Views/Plans/context/actions';
import { isSubscribiliUser } from 'Shared/Utils';
import { USER_SERVICES } from 'Services';
import useStyles from './styles';

const DISCOUNT_TYPES = {
  discount_percent: '%',
  flat_price: '$',
  discount_price: '$ off',
};

const NEW_SERVICE = {
  code: '',
  name: '',
  discount_type: 'discount_percent',
  discount_value: '',
  enableLifeTimeValue: true
};

const hasEnableLifeTimeValueChanged = (intialValues, currValues) => {
  return intialValues?.some(
    (item, index) =>
      item?.enableLifeTimeValue !== currValues?.[index]?.enableLifeTimeValue,
  );
};

const CategoryDiscounts = (props) => {
  const {
    level,
    levelId,
    planPackageId,
    otherButtons = '',
    setExecuteHandleSubmit
  } = props;

  const { state: { user }, dispatch: globalDispatch } = useContext(Context);

  const {
    state: { highlightErrors, discounts = {}, isPlansByExcel = false,  payment_cpt_codes: exludedLifeTimeValueCodes = [] },
    dispatch,
  } = useContext(PlansContext);

  const subscribiliUser = isSubscribiliUser(user);
  const viewOnly = isPlansByExcel;

  const {
    initData = {},
    discount_level = 'global',
    discount_value = '',
    categories: categoryList = [],
    services: serviceList = [],
    formUpdated = false,
    formValid = true,
  } = discounts;

  const [categoryLoading, setCategoryLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lifeTimeValueChanged, setLifeTimeValueChanged] = useState(false);

  const noDiscount = discount_level === 'no_discount';
  const globalDiscount = discount_level === 'global';
  const categoryDiscount = discount_level === 'category';

  const classes = useStyles();

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const onCheckboxChange = (dIndex) => {
    const clonedValue = _.cloneDeep(categoryList);

    if (dIndex !== undefined)
      clonedValue[dIndex].is_display = !Boolean(
        clonedValue?.[dIndex]?.is_display,
      );
    else
      clonedValue.forEach((category) => (category.is_display = !allSelected));

    updateCategoryValues({
      categories: clonedValue,
    });
  };

  const onTextfieldChange = (evt, dIndex) => {
    const { name: dName, value: dValue } = evt?.target || {};

    let replacedValue =
      dValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)?.[0] || '0';
    let parsedValue = parseFloat(replacedValue);
    parsedValue = parsedValue > 100 ? 100 : parsedValue;

    if (dIndex !== undefined) {
      const clonedValue = _.cloneDeep(categoryList);
      clonedValue[dIndex][dName] = parsedValue;

      updateCategoryValues({
        categories: clonedValue,
      });
    } else {
      const clonedValue = _.cloneDeep(categoryList);
      clonedValue.forEach(
        (category) => (category.discount_value = parsedValue),
      );

      updateCategoryValues({
        categories: clonedValue,
        discount_value: parsedValue,
      });
    }
  };

  const onServiceFieldChange = (evt, dIndex) => {
    const { name: dName, value: dValue, max } = evt?.target || {};

    let parsedValue = dValue;

    if (dName === 'discount_value') {
      let replacedValue =
        dValue?.toString()?.match(/^-?\d{0,5}(?:\.\d{0,2})?/)?.[0] || '0';
      parsedValue = replacedValue;
    }

    if (max) {
      let dMax = parseFloat(max);
      let replacedValue =
        dValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)?.[0] || '0';
      parsedValue = parseFloat(replacedValue);
      parsedValue = parsedValue > dMax ? dMax : parsedValue;
    }

    if (dIndex !== undefined) {
      const clonedValue = _.cloneDeep(serviceList);
      clonedValue[dIndex][dName] = parsedValue;
      updateCategoryValues({
        services: clonedValue,
      });
    }
  };

  const handleRadioChange = (event) => {
    const targetValue = event?.target?.value || 'global';

    if (targetValue === 'global') {
      const clonedValue = _.cloneDeep(categoryList);
      clonedValue.forEach(
        (category) => (category.discount_value = discount_value),
      );

      updateCategoryValues({
        categories: clonedValue,
        discount_level: targetValue,
      });
    } else {
      updateCategoryValues({
        discount_level: targetValue,
      });
    }
  };

  const addProcedureCode = () => {
    let clonedList = _.cloneDeep(serviceList);
    clonedList.push(NEW_SERVICE);

    updateCategoryValues({
      services: clonedList,
    });
  };

  const deleteProcedureCode = (delId) => {
    let clonedList = _.cloneDeep(serviceList);
    clonedList.splice(delId, 1);

    updateCategoryValues({
      services: clonedList,
    });
  };

  const updateCategoryValues = (updatedValues) =>
    dispatch({
      type: UPDATE_CATEGORY_DISCOUNTS,
      payload: {
        ...discounts,
        ...updatedValues,
      },
    });

  const renderToggle = (dIndex) => {
    const disabled = subscribiliUser;
    return (
      <Grid container className={classes.tabsContainer}>
        <Tabs
          value={serviceList?.[dIndex]?.discount_type}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={(_event, newValue) =>
            onServiceFieldChange(
              { target: { name: 'discount_type', value: newValue } },
              dIndex,
            )
          }
          variant="scrollable"
          scrollButtons="auto"
          className={classes.discountTabs}
        >
          {Object.keys(DISCOUNT_TYPES).map((indKey) => (
            <Tab
              key={indKey}
              value={indKey}
              label={DISCOUNT_TYPES[indKey]}
              disabled={disabled}
            />
          ))}
        </Tabs>
      </Grid>
    );
  }; 

  const getPayload = () => {
    let payload = {
      plan_package_id: planPackageId,
      categories: [],
      services: [],
    };

    const updatedCat = categoryList.map((category) => ({
      ...category,
      discount_type: 'discount_percent',
      discount_value: category?.discount_value || 0,
    }));

    const updatedServ = serviceList.map((service) => ({
      ...service,
      enableLifeTimeValue: undefined,
      retail_price: isNaN(parseInt(service?.retail_price))
        ? 0
        : service?.retail_price,
    }));

    if (noDiscount) {
      payload.discount_level = 'global';
      payload.global_discount = {
        discount_type: 'no_discount',
      };
    } else if (globalDiscount) {
      payload.discount_level = 'global';
      payload.global_discount = {
        discount_type: 'discount_percent',
        discount_value,
      };

      payload.categories = updatedCat;
      payload.services = updatedServ;
    } else if (categoryDiscount) {
      payload.discount_level = 'category';

      payload.categories = updatedCat;
      payload.services = updatedServ;
    }

    return payload;
  };

  const checkForErrors = () => {
    let hasErrors = false;

    if (!(viewOnly || formValid)) {
      dispatch({ type: HIGHLIGHT_ERRORS });

      hasErrors = true;
    }

    return hasErrors;
  };

  const handleSaveClick = async () => {
    const formHasErrors = checkForErrors();
    if (!planPackageId || formHasErrors) return;

    setIsLoading(true);

    if (lifeTimeValueChanged) {
      setExecuteHandleSubmit(true);
      return;
    }

    try {
      const params = planPackageId ? `?plan_package_id=${planPackageId}` : '';
      const payload = getPayload();

      const response = await USER_SERVICES.postProcedureCategories(
        level,
        levelId,
        params,
        payload,
      );

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', 'Details updated successfully');

        fetchProcedureCategory().then(() => {});
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);

      dispatchGlobalNotification(
        'error',
        err?.message || err?.error || 'Error updating the details',
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (Object.keys(initData).length) {
      let validForm = true;

      const discountTypeChanged = !_.isEqual(
        discount_level,
        initData?.discount_level || 'global',
      );
      const discountValueChanged =
        globalDiscount && !_.isEqual(discount_value, initData?.discount_value);
      const categoryValueChanged =
        categoryDiscount &&
        !_.isEqual(categoryList, initData?.categories || []);

      let validServices = true;
      const customCodeChanged = !_.isEqual(
        serviceList,
        initData?.services || [],
      );

      if (customCodeChanged && serviceList.length)
        serviceList.forEach(
          (service) =>
            (validServices = Object.keys(service).every(
              (sKey) =>
                sKey === 'retail_price' ||
                sKey === 'enableLifeTimeValue' ||
                service[sKey],
            )),
        );
      if (globalDiscount) {
        validForm = Boolean(discount_value) && validServices;
      } else if (categoryDiscount) {
        validForm =
          validServices &&
          categoryList.some(
            (category) => category?.is_display && category?.discount_value,
          );
      }

      updateCategoryValues({
        formUpdated:
          discountTypeChanged ||
          discountValueChanged ||
          categoryValueChanged ||
          customCodeChanged,
        formValid: validForm,
      });
    }
  }, [initData, categoryList, serviceList, discount_level, discount_value]);


  useEffect(() => {
    setLifeTimeValueChanged(
      hasEnableLifeTimeValueChanged(initData?.services, serviceList),
    );
  }, [serviceList]);

  const fetchProcedureCategory = async () => {
    setCategoryLoading(true);

    try {
      const params = planPackageId
        ? `?plan_package_id=${planPackageId}&strict_level=no`
        : '?default=yes';
      const response = await USER_SERVICES.getProcedureCategories(
        level,
        levelId,
        params,
      );

      if (response?.type === 'success' && response?.data) {
        const resp = response?.data?.rows?.[0] || {};
        let discountLevel = resp?.discount_level;
        let discountType = resp?.global_discount?.discount_type;
        let discountValue = resp?.global_discount?.discount_value || 0;

        let categories = resp?.categories || [];
        let services =
          resp?.services?.map((service) => {
            return {
              ...service,
              enableLifeTimeValue: !exludedLifeTimeValueCodes?.includes(
                service?.code,
              ),
            };
          }) || [];

        if (discountLevel === 'global' && discountType === 'no_discount') {
          discountLevel = 'no_discount';
          discountValue = 0;
        }

        if (discountLevel === 'global') {
          categories = resp?.categories.map((category) => ({
            ...category,
            discount_value: resp?.global_discount?.discount_value || 0,
          }));
        }

        const initData = {
          discount_level: discountLevel,
          discount_value: discountValue,
          categories,
          services,
        };

        updateCategoryValues({
          initData,
          ...initData,
        });

        dispatch({
          type: 'PLANS_BY_EXCEL',
          payload: {
            isPlansByExcel: resp.summary_mode === 'excel_upload',
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);

      updateCategoryValues({
        initData: {},
        discount_level: 'global',
        discount_value: '',
        categories: [],
        services: [],
      });
    }

    setCategoryLoading(false);
  };

  useEffect(() => {
    if (levelId) fetchProcedureCategory().then(() => {});
  }, [levelId]);

  useEffect(() => () => dispatch({ type: RESET_CATEGORY_DISCOUNTS }), []);

  const errorForm = Boolean(highlightErrors && !formValid);

  const categoryData = categoryList.map((category) => {
    return {
      ...category,
      code: `${category?.range_start} - ${category?.range_end}`,
      actions: category,
    };
  });

  const servicesData = serviceList.map((service) => {
    return {
      ...service,
      actions: service,
    };
  });

  const getTableData = () => {
    switch (discount_level) {
      case 'category':
        return [...categoryData, ...servicesData];
      case 'global':
        return viewOnly
          ? [...servicesData]
          : [...categoryData, ...servicesData];
      default:
        return [];
    }
  };

  const tableData = getTableData();

  const renderTextInput = (value, name, rowId, props = {}, inputProps = {}) => (
    <TextField
      required
      fullWidth
      variant="outlined"
      margin="dense"
      value={value === '-' ? '' : value}
      name={name}
      inputProps={{
        'data-testid': `category-input-${name}-${rowId}`,
        ...inputProps,
      }}
      {...props}
    />
  );

  const xsmallCellProps = () => ({ className: classes.xsmallColumn });
  const smallCellProps = () => ({ className: classes.smallColumn });
  const mediumCellProps = () => ({ className: classes.mediumColumn });
  const largeCellProps = () => ({ className: classes.largeColumn });

  const allSelected = categoryList.every((category) => category?.is_display);

  const renderTableHead = () => {
    if (tableData.length === serviceList.length) return ' ';

    return (
      <CheckBox
        checked={allSelected || globalDiscount}
        onChange={() => onCheckboxChange()}
        inputProps={{
          'data-testid': `category-select-discount-checkbox-all`,
        }}
        disabled={globalDiscount || subscribiliUser}
      />
    );
  };

  const categoryTableColumns = [
    {
      name: 'is_display',
      label: renderTableHead(),
      options: {
        setCellHeaderProps: xsmallCellProps,
        customBodyRender: (_value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          const data = categoryList?.[rowIndex];

          return (
            <CheckBox
              checked={data?.is_display || globalDiscount || false}
              onChange={() => onCheckboxChange(rowIndex)}
              name={data?.name}
              inputProps={{
                'data-testid': `category-select-discount-checkbox-${rowIndex}`,
              }}
              disabled={globalDiscount || subscribiliUser}
            />
          );
        },
      },
    },
    {
      name: 'code',
      label: 'CPT Code/Range',
      options: {
        setCellHeaderProps: mediumCellProps,
        customBodyRender: (value) => {
          return value || '-';
        },
      },
    },
    {
      name: 'name',
      label: 'Description',
      options: {
        setCellHeaderProps: largeCellProps,
        customBodyRender: (value) => {
          return value || '-';
        },
      },
    },
    {
      name: 'discount_value',
      label: 'Discount Value',
      options: {
        setCellHeaderProps: smallCellProps,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          let maxValue = 100;
          let disabled = globalDiscount || subscribiliUser;

          return renderTextInput(
            value,
            'discount_value',
            rowIndex,
            {
              onChange: (evt) => {
                onTextfieldChange(evt, rowIndex);
              },
              placeholder: 0,
              error: errorForm && !value,
            },
            {
              type: 'number',
              min: 0,
              step: 1,
              max: maxValue,
              disabled,
            },
          );
        },
      },
    },
    {
      name: 'discount_type',
      label: 'Discount Type',
      options: {
        setCellHeaderProps: smallCellProps,
        customBodyRender: (_value) => {
          return '%';
        },
      },
    },
    {
      name: '',
      options: {
        setCellHeaderProps: mediumCellProps,
      },
    },
  ];

  const excelCategoryTableColumns = [
    {
      name: 'list_no',
      label: 'List',
      options: {
        setCellHeaderProps: xsmallCellProps,
        customBodyRender: (_value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          return rowIndex + 1;
        },
      },
    },
    {
      name: 'code',
      label: 'CPT Code/Range',
      options: {
        setCellHeaderProps: smallCellProps,
      },
    },
    {
      name: 'name',
      label: 'Description',
      options: {
        setCellHeaderProps: mediumCellProps,
      },
    },
    {
      name: 'discount_value',
      label: 'Discount Percentage',
      options: {
        setCellHeaderProps: mediumCellProps,
        customBodyRender: (value) => value || '-',
      },
    },
    {
      name: 'discount_type',
      label: 'Discount Type',
      options: {
        setCellHeaderProps: smallCellProps,
        customBodyRender: () => '%',
      },
    },
  ];

  const serviceTableColumns = [
    {
      name: 'is_display',
      label: ' ',
      options: {
        setCellHeaderProps: xsmallCellProps,
        customBodyRender: (_value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          const showAddButton = rowIndex + 1 >= serviceList.length;

          if (!showAddButton) return <></>;

          return (
            <IconButton
              color="secondary"
              title="Add Custom Code"
              aria-label="add-procedure-code"
              onClick={addProcedureCode}
              data-testid={`add-procedure-code-button-${rowIndex}`}
              id={'category-discounts-services'}
            >
              <AddRoundedIcon fontSize="inherit" />
            </IconButton>
          );
        },
      },
    },
    {
      name: 'enableLifeTimeValue',
      label: 'Lifetime Value',
      options: {
        setCellHeaderProps: xsmallCellProps,
        customBodyRender: (_value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          const data = servicesData?.[rowIndex];
          const isChecked = servicesData?.[rowIndex]?.enableLifeTimeValue;
          return (
            <CheckBox
              checked={isChecked}
              disabled={subscribiliUser}
              onChange={(_event, newValue) =>
                onServiceFieldChange(
                  { target: { name: 'enableLifeTimeValue', value: newValue } },
                  rowIndex,
                )
              }
              name={data?.name}
              inputProps={{
                'data-testid': `lifetime-payment-cpt-codes-checkbox-${rowIndex}`,
              }}
            />
          );
        },
      },
    },
    {
      name: 'code',
      label: 'CPT Code/Range',
      options: {
        setCellHeaderProps: mediumCellProps,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          let disabled = subscribiliUser;

          return renderTextInput(
            value,
            'code',
            rowIndex,
            {
              onChange: (evt) => {
                onServiceFieldChange(evt, rowIndex);
              },
              error: errorForm && !value,
            },
            {
              disabled
            },
          );
        },
      },
    },
    {
      name: 'name',
      label: 'Description',
      options: {
        setCellHeaderProps: largeCellProps,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          let disabled = subscribiliUser;

          return renderTextInput(
            value,
            'name',
            rowIndex,
            {
              onChange: (evt) => {
                onServiceFieldChange(evt, rowIndex);
              },
              error: errorForm && !value,
            },
            {
              disabled
            },
          );
        },
      },
    },
    {
      name: 'discount_value',
      label: 'Discount Value',
      options: {
        setCellHeaderProps: smallCellProps,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          const maxValue =
            serviceList?.[rowIndex]?.discount_type === 'discount_percent' &&
            100;
          const disabled = subscribiliUser;

          return renderTextInput(
            value,
            'discount_value',
            rowIndex,
            {
              onChange: (evt) => {
                onServiceFieldChange(evt, rowIndex);
              },
              placeholder: 0,
              error: rowIndex && errorForm && !value,
            },
            {
              type: 'number',
              min: 0,
              step: 1,
              max: maxValue,
              disabled,
            },
          );
        },
      },
    },
    {
      name: 'discount_type',
      label: 'Discount Type',
      options: {
        setCellHeaderProps: smallCellProps,
        customBodyRender: (_value, tableMetaData) => {
          const { rowIndex } = tableMetaData;
          return renderToggle(rowIndex);
        },
      },
    },
    {
      name: '',
      options: {
        setCellHeaderProps: mediumCellProps,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          return (
            <div className="d-flex flex-no-wrap">
              {rowIndex >= 0 && (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    deleteProcedureCode(rowIndex);
                  }}
                  data-testid={`delete-procedure-button-${rowIndex}`}
                  disabled={subscribiliUser}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  const excelServiceTableColumns = [
    {
      name: 'list_no',
      label: 'List',
      options: {
        setCellHeaderProps: xsmallCellProps,
        customBodyRender: (_value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          return rowIndex + 1;
        },
      },
    },
    {
      name: 'code',
      label: 'CPT Code/Range',
      options: {
        setCellHeaderProps: smallCellProps,
      },
    },
    {
      name: 'name',
      label: 'Description',
      options: {
        setCellHeaderProps: mediumCellProps,
      },
    },
    {
      name: 'discount_percentage',
      label: 'Discount Percentage',
      options: {
        setCellHeaderProps: mediumCellProps,
        customBodyRender: (value) => value || '-',
      },
    },
    {
      name: 'discount_value',
      label: 'Discount Price',
      options: {
        setCellHeaderProps: largeCellProps,
        customBodyRender: (value) => `$${value}`,
      },
    },
  ];

  const renderDiscountsType = () => {
    if (viewOnly) {
      return (
        <>
          {noDiscount && (
            <Typography className="mg_top_20">
              No discounts available for this plan
            </Typography>
          )}
        </>
      );
    }

    return (
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item className={classes.subtitle}>
          <Typography>Configure the plan discounts :</Typography>
        </Grid>
        <Grid item>
          <RadioGroup
            className={viewOnly ? 'pointer-events-none' : ''}
            aria-label="discount_types"
            name="Discount Types"
            value={discount_level}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="no_discount"
              control={
                <Radio
                  disabled={subscribiliUser}
                  inputProps={{
                    'data-testid': 'category-discount-radio-no-discount',
                  }}
                />
              }
              label="No Discounts"
            />
            <FormControlLabel
              value="global"
              className={classes.flatChargeForm}
              control={
                <Radio
                  disabled={subscribiliUser}
                  inputProps={{
                    'data-testid': 'category-discount-radio-flat-discount',
                  }}
                />
              }
              label={
                <>
                  <Grid item xs={12} className="d-flex align-center">
                    <TextField
                      required
                      name="flat_discount_value"
                      value={Number(discount_value).toString()}
                      onChange={(e) => onTextfieldChange(e)}
                      variant="outlined"
                      placeholder="0"
                      error={
                        highlightErrors && globalDiscount && !discount_value
                      }
                      inputProps={{
                        type: 'number',
                        min: 0,
                        max: 100,
                        step: 1,
                        'data-testid': 'category-input-discount-value-flat',
                        className: classes.flatDiscountInput,
                        disabled: categoryDiscount || subscribiliUser,
                      }}
                    />
                    <Typography variant="h6" className={classes.percentLabel}>
                      %
                    </Typography>
                    Flat Discount for all categories
                  </Grid>

                  {Boolean(
                    highlightErrors && globalDiscount && !discount_value,
                  ) && (
                    <Grid item xs={12}>
                      <FormHelperText error>
                        {' '}
                        Discount Value is required{' '}
                      </FormHelperText>
                    </Grid>
                  )}
                </>
              }
            />
            <FormControlLabel
              value="category"
              control={
                <Radio
                  disabled={subscribiliUser}
                  inputProps={{
                    'data-testid': 'category-discount-radio-per-discount',
                  }}
                />
              }
              label="Discounts by categories"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    );
  };

  const renderSaveChangesButton = () => (
    <>
      {planPackageId && formUpdated && (
        <Grid item>
          {otherButtons}
          <Button
            onClick={handleSaveClick}
            color="secondary"
            variant="contained"
            size="large"
            data-testid={`category-discount-save-button`}
            disabled={isLoading || !formValid}
          >
            {isLoading && (
              <CircularProgress size={25} className={'color_white'} />
            )}
            {!isLoading && 'Save Changes'}
          </Button>
        </Grid>
      )}
    </>
  );

  const options = {
    filter: false,
    sort: false,
    selectableRows: 'none',
    responsive: 'standard',
    print: false,
    pagination: false,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
  };

  const customServiceOptions = {
    filter: false,
    sort: false,
    selectableRows: 'none',
    responsive: 'standard',
    print: false,
    pagination: true,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
  };

  if (categoryLoading)
    return (
      <Grid container justifyContent="center" className="position-relative">
        <CircularProgress size={25} thickness={5} />
      </Grid>
    );

  return (
    <div
      className={clsx('widget-wrapper', isLoading && 'disable-form')}
      elevation={0}
    >
      <SectionHeader
        title="Procedure Discounts"
        titleVariant="h5"
        titleComponent="h5"
        titleJustify="flex-start"
        gutterBottom
        rightSection={
          viewOnly && (
            <Tooltip
              arrow
              title={
                <Typography variant="body1">
                  Discounts are driven by excel
                </Typography>
              }
            >
              <InfoIcon
                className={classes.infoIcon}
                fontSize="small"
                color="primary"
              />
            </Tooltip>
          )
        }
      />
      <Divider />

      <Grid
        container
        id="discount-info"
        spacing={1}
        className={classes.discountContainer}
        justifyContent="space-between"
      >
        <Grid item>{renderDiscountsType()}</Grid>

        <Grid item className="d-flex align-center">
          {renderSaveChangesButton()}
        </Grid>
      </Grid>

      {Boolean(!noDiscount) && (
        <Grid container spacing={2} id="category-discounts-table">
          <Grid item xs={12}>
            {(globalDiscount || categoryDiscount) && !viewOnly && (
              <MUIDataTable
                title="Category Data"
                data={categoryData}
                columns={
                  viewOnly ? excelCategoryTableColumns : categoryTableColumns
                }
                options={options}
              />
            )}

            {!viewOnly && (
              <Grid
                item
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <SectionHeader
                  title="Service Data"
                  titleVariant="h6"
                  titleComponent="h6"
                  titleJustify="space-between"
                  className={classes.serviceDataHeader}
                  rightSection={
                    <Button
                      startIcon={<AddRoundedIcon fontSize="large" />}
                      size="large"
                      color="secondary"
                      onClick={addProcedureCode}
                      data-testid="add-procedure-code-button"
                      disabled={noDiscount || subscribiliUser}
                    >
                      Add Custom Code
                    </Button>
                  }
                />
              </Grid>
            )}

            <MUIDataTable
              data={servicesData}
              columns={
                viewOnly ? excelServiceTableColumns : serviceTableColumns
              }
              options={customServiceOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent={'flex-end'}
            >
              {renderSaveChangesButton()}
              <Grid item>
                {errorForm && (
                  <Typography className={classes.errorText} color="error">
                    * Please fill the required fields
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

CategoryDiscounts.propTypes = {
  level: PropTypes.string,
  levelId: PropTypes.number,
  planPackageId: PropTypes.number,
  otherButtons: PropTypes.string,
  viewOnly: PropTypes.bool,
  setExecuteHandleSubmit: PropTypes.func
};

export default CategoryDiscounts;
