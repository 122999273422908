import Utils from "Shared/Utils";
import httpService from "../Api/http.service";

const fetchAllPartners = (paramsObj, product = 'recur') => {
  let qParams = Utils.getQueryString({ ...paramsObj, product });
  qParams = qParams ? `?${qParams}` : '';

  return httpService({
    url: `/partners${qParams}`,
    method: "get",
  });
};

const createPartner = (payload) =>
  httpService({
    url: `/partner`,
    method: "post",
    data: payload,
  });

const deletePartner = (payload) => 
  httpService({
    url: `/partner`,
    method: "delete",
    data: payload,
  });

const partnerService = {
    createPartner,
    deletePartner,
    fetchAllPartners
};

export default partnerService;
