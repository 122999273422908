import {
  GROUP_CHANGED,
  PLAN_UPDATED,
  PRODUCT_INFO_UPDATED,
  PLANS_BY_EXCEL,
  CPT_UPDATED,
  PLAN_DETAILS_UPDATED,
  TIER_UPDATED,
  REVSHARE_UPDATED,
  SUBSCRIBILI_COMMISSION_UPDATED,
  COMMISSION_UPDATED,
  VENDOR_PAYOUT_UPDATED,
  HIGHLIGHT_ERRORS,
  UPDATE_FIELDS_VALIDITY,
  UPDATE_PRICE_VALIDITY,
  SAVING_PLAN,
  SAVE_ERROR,
  PLAN_INFO_RECEIVED,
  RESET_PLAN,
  UPDATE_CATEGORY_DISCOUNTS,
  RESET_CATEGORY_DISCOUNTS,
  MEMBER_TIER_UPDATED,
  UPDATE_VALID_MEMBER_TO,
} from './actions';
import { SUBSCRIPTION_TYPE } from 'global-constants/constants';

const initialDiscounts = {
  initData: {},
  discount_level: 'global',
  discount_value: '',
  categories: [],
  services: [],
  formUpdated: false,
  formValid: true,
};

const initialDataState = {
  productInfo: {
    plan_name: '',
    plan_package_type: 'Base',
    member_types: [SUBSCRIPTION_TYPE.CUSTOMER.value],
    description: '',
    statement_descriptor: '',
    additional_members: 0,
    addon_allowed: false,
    allow_future_dated: false,
    allowFutureDateToggle: true,
    renewal_plan_pkg_id: '',
    plan_status: 'Active',
    disclosure_text: '',
  },
  cpt_codes: [],
  plans: [],
  isValid: {
    group: false,
    product: false,
    cpt: true,
    priceValidity: true,
    memberTypeSelected: false,
  },
  isValidPrice: {
    0: { details: true, tiers: true, payout: true, member_tier: true },
  },
  highlightErrors: 0,
  isSavingPlan: false,
  discounts: { ...initialDiscounts },
};

const initialState = {
  selectedGroup: '',
  isPlansByExcel: false,
  isValidMemberTo: true,
  selectedPriceModel: 'Single',
  initialProductInfo: initialDataState.productInfo,
  default_cpt_value: {
    list: 1,
    pdt_name: '',
    code: '',
    duplicate_code: '',
    description: '',
    quantity: '',
    frequency: 'annually',
    total_unit: '',
    retail_price: '',
    subscription_price: '',
    cpt_display: 'all',
  },
  default_tier_value: {
    up_to: '',
    unit_amount: '',
    unit_charge_amount: 0,
    upfront_amount: 0,
    upfront_charge_amount: 0,
    signup_amount: 0,
    signup_charge_amount: 0,
  },
  default_commission: {
    partner: 0,
    group: 0,
    region: 0,
    location: 0,
    provider: 0,
  },
  default_plan: {
    plan_details: {
      member_type: 'General',
      billing_frequency: '',
      price_description: '',
      stripe_fee_paid_by: 'subscriber',
      private_only: false,
      status: 'Active',
    },
    tiers: [],
    revshare_payout: {
      enabled: false,
      revshare_id: '',
      payout_type: 'percent',
      amount: 0,
    },
    member_tier: {
      member_from: 1,
      member_to: 1,
      price_name: '',
    },
    subscribili_comm: {
      type: 'fixed',
      primary: 0,
      dependent: 0,
    },
    commission: {
      type: 'percent',
      values: [],
    },
    vendor_payout: {
      values: [
        {
          month: '',
          primary: 0,
          dependent: 0,
        },
      ],
    },
  },
  ...initialDataState,
};

const reducer = (state, { type, payload }) => {
  const getUpdatedPlan = (parameter) => {
    return state.plans.map((plan, index) =>
      index === payload.planIndex
        ? { ...plan, [parameter]: payload[parameter] }
        : plan,
    );
  };

  switch (type) {
    case UPDATE_VALID_MEMBER_TO: {
      return {
        ...state,
        isValidMemberTo: payload.isValidMemberTo,
      };
    }
    case GROUP_CHANGED: {
      return {
        ...state,
        selectedGroup: payload.selectedGroup,
        isValid: {
          ...state.isValid,
          group: true,
        },
      };
    }
    case PLAN_UPDATED: {
      return {
        ...state,
        plans: payload.plans,
      };
    }
    case PRODUCT_INFO_UPDATED: {
      return {
        ...state,
        productInfo: payload.productInfo,
      };
    }
    case PLANS_BY_EXCEL: {
      return {
        ...state,
        isPlansByExcel: payload.isPlansByExcel,
      };
    }
    case CPT_UPDATED: {
      return {
        ...state,
        cpt_codes: payload.cpt_codes,
      };
    }
    case PLAN_DETAILS_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('plan_details'),
      };
    }
    case TIER_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('tiers'),
      };
    }
    case MEMBER_TIER_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('member_tier'),
      };
    }
    case REVSHARE_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('revshare_payout'),
      };
    }
    case SUBSCRIBILI_COMMISSION_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('subscribili_comm'),
      };
    }
    case COMMISSION_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('commission'),
      };
    }
    case VENDOR_PAYOUT_UPDATED: {
      return {
        ...state,
        plans: getUpdatedPlan('vendor_payout'),
      };
    }
    case HIGHLIGHT_ERRORS: {
      return {
        ...state,
        highlightErrors: state.highlightErrors + 1,
      };
    }
    case UPDATE_FIELDS_VALIDITY: {
      return {
        ...state,
        isValid: {
          ...payload.isValid,
          group: Boolean(state.selectedGroup),
        },
      };
    }
    case UPDATE_PRICE_VALIDITY: {
      return {
        ...state,
        isValidPrice: payload.isValidPrice,
      };
    }
    case SAVING_PLAN: {
      return {
        ...state,
        isSavingPlan: true,
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        isSavingPlan: false,
      };
    }
    case PLAN_INFO_RECEIVED: {
      return {
        ...state,
        ...payload.planInfo,
        initialProductInfo: payload.planInfo.productInfo,
      };
    }
    case UPDATE_CATEGORY_DISCOUNTS: {
      return {
        ...state,
        discounts: {
          ...payload,
        },
      };
    }
    case RESET_CATEGORY_DISCOUNTS: {
      return {
        ...state,
        discounts: {
          ...initialDiscounts,
        },
      };
    }
    case RESET_PLAN: {
      return {
        ...initialState,
      };
    }
    default:
      throw new Error();
  }
};

export { reducer, initialState };
