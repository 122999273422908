import { createTheme, responsiveFontSizes } from '@material-ui/core';
import default_palette from './default-palette';
import dark_palette from './dark-palette';
import green_palette from './green-palette';

const generateTheme = (palette) =>
  responsiveFontSizes(
    createTheme({
      palette,
      layout: {
        contentWidth: 1236,
      },
      typography: {
        fontFamily: 'Poppins',
        fontSize: 11.5,

        h2: {
          fontWeight: 500,
        },
        h3: {
          fontWeight: 500,
        },
        h4: {
          fontWeight: 500,
          lineHeight: 1.5,
        },
        h5: {
          lineHeight: 1.6,
        },
        h6: {
          fontSize: 16,
        },
        body1: {
          lineHeight: 1.9,
          fontSize: 13.5,
        },
        body2: {
          lineHeight: 1.8,
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            ':root': {
              '--placeholder': '#787697',
              '--theme-primary-main': palette.primary.main,
              '--theme-primary-light': palette.primary.light,
              '--theme-secondary-main': palette.secondary.main,
              '--theme-secondary-light': palette.secondary.light,
              '--theme-highlight-color': palette.primary.highlight,
              '--theme-highlight-color-2': palette.primary.highlight3,
              '--theme-highlight-color-bg': palette.primary.highlight2,
              '--theme-cotrast-color': palette.primary.contrastText,
              '--theme-text-primary': palette.text.primary,
              '--theme-text-secondary': palette.text.secondary,
              '--theme-text-light': palette.text.light,
              '--theme-text-disabled': palette.text.disabled,
              '--theme-background-alternate': palette.alternate.main,
              '--theme-login-gradiant': palette.background.loginGradiant,
              '--theme-level-onboard-gradiant':
                palette.background.levelOnboardGradiant,
              '--theme-level-onboard-agreement-bg':
                palette.background.levelOnboardBg,
              '--theme-table-header': palette.table_head,
              '--theme-text-dark': '#000',
              '--theme-success': '#38ada9',
              '--theme-text-success': palette.text.success,
              '--theme-error': '#ff4757',
              '--theme-warning': '#ff9800',
              '--theme-text-light-grey': palette.text.lightGrey,
              '--theme-background-variant1': palette.background.variant1,
            },
            '.wp-content': {
              fontFamily: 'Poppins',
              fontSize: 13.5,
              color: palette.text.primary,

              '& h2': {
                fontWeight: 500,
              },
              '& h3': {
                fontWeight: 500,
              },
              '& h4': {
                fontWeight: 500,
                lineHeight: 1.5,
              },
              '& h5': {
                lineHeight: 1.6,
              },
              '& h6': {
                fontSize: 16,
              },

              '& p': {
                margin: '20px 0',
              },

              '& li': {
                margin: '20px ',
                listStyleType: 'lower-alpha',

                '& li': {
                  listStyleType: 'lower-roman',
                },
              },

              '& .heading': {
                fontSize: '2rem',
                margin: '40px 0 0',
              },
              '& .subHeading': {
                fontSize: '1.5rem',
                margin: '35px 0 0',
              },
              '& .sectionHeading': {
                fontSize: '1.25rem',
                margin: '35px 0 0',
              },
            },
            '.hero-banner': {
              '& .page-title': {
                display: 'none',
              },
            },
          },
        },
        MuiCheckbox: {
          root: {
            '&.Mui-checked': {
              color: palette.primary.highlight,
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        MuiButton: {
          root: {
            borderRadius: 12,
            textTransform: 'none',
            padding: '10px 16px',
            minWidth: 100,
            boxShadow: 'none',
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          containedSecondary: {
            background: `linear-gradient(120.39deg, ${palette.secondary.main} 0.92%, ${palette.secondary.light} 102.21%)`,
            color: palette.primary.contrastText,
            '&:hover': {},
            '&$disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
            },
          },
          label: {
            fontSize: '0.9rem',
            lineHeight: '2rem',
          },
          outlined: {
            padding: '8px 16px',
            background: palette.background.paper,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: palette.background.paper,
              border: `2px solid ${palette.secondary.main}`,
              boxShadow: 'none',
            },
          },
          outlinedSecondary: {
            border: `1px solid ${palette.secondary.main}`,
            color: palette.secondary.main,
            background: palette.background.paper,
            '&:hover': {
              border: `1px solid ${palette.secondary.main}`,
              backgroundColor: palette.background.paper,
            },
          },
          textSecondary: {
            color: palette.secondary.main,
          },
          textSizeLarge: {
            minWidth: 120,
          },
        },
        MuiIconButton: {
          colorPrimary: {
            '&.contained': {
              backgroundColor: palette.primary.main,
              color: palette.primary.contrastText,
            },
          },
          sizeSmall: {
            fontSize: '1.2rem',
          },
        },
        MuiOutlinedInput: {
          input: {
            '&:-webkit-autofill': {
              '-webkit-box-shadow': '0 0 0 100px #fff inset',
              '-webkit-text-fill-color': palette.text.primary,
            },
          },
          inputMarginDense: {
            lineHeight: '19px',
          },
          root: {
            borderRadius: 12,
          },
        },
        MuiSelect: {
          outlined: {
            borderRadius: '12px !important',
            background: palette.background.paper,
          },
          select: {
            '&:focus': {
              backgroundColor: palette.background.paper,
            },
          },
        },
        MuiSvgIcon: {
          root: {
            fontSize: '24px',
          },
        },
        MuiChip: {
          outlinedPrimary: {
            background: palette.primary.light,
          },
        },

        MuiFormControl: {
          root: {
            borderRadius: 12,
            '&.xtrasmall-width': {
              width: 120,
            },
            '&.small-width': {
              width: 200,
            },
            '&.medium-width': {
              width: 280,
            },
          },
        },
        MuiPaper: {
          rounded: {
            borderRadius: 16,
            '&.widget-wrapper': {
              padding: 24,
              border: `1px solid ${palette.text.light}`,
              height: '100%',
              '&.material-wrapper': {
                padding: 15,
                minHeight: 0,
              },
            },

            '&.min-height': {
              minHeight: 405,
            },
          },
        },
        MuiLinearProgress: {
          root: {
            borderRadius: 12,
            height: 6,
          },
          bar: {
            borderRadius: 12,
          },
          barColorPrimary: {
            backgroundColor: palette.table_head,
          },
          colorSecondary: {
            backgroundColor: palette.background.progressBG,
          },
        },
        MuiTab: {
          root: {
            '&.compact': {
              minWidth: 0,
            },
          },
          textColorSecondary: {
            color: palette.primary.highlight,
            fontWeight: 600,

            '&$selected': {
              color: palette.primary.highlight,
            },
          },
        },
        MuiTabScrollButton: {
          root: {
            width: 25,
            '&$disabled': {
              width: 0,
              transition: 'all 0.2s',
            },
          },
        },
        PrivateTabIndicator: {
          root: {
            height: 3,
          },
          colorSecondary: {
            backgroundColor: palette.primary.highlight,
          },
        },
        MuiToggleButton: {
          root: {
            border: 0,
            color: palette.primary.highlight,
            '&$selected': {
              background: `
              linear-gradient(95.69deg, 
              ${palette.primary.highlight} 4.34%, 
              ${palette.primary.highlight2} 55.62%, 
              ${palette.primary.highlight3} 91.79%)
            `,
              color: palette.secondary.contrastText,
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          sizeSmall: {
            padding: 5,
          },
          label: {
            fontSize: '0.7rem',
          },
        },
        MuiToggleButtonGroup: {
          root: {
            border: `1px solid ${palette.primary.highlight}`,
            padding: 4,
            borderRadius: 8,
            '&.rectangle': {
              borderRadius: 8,
            },
          },
          groupedHorizontal: {
            minWidth: 75,
            border: 0,
            borderRadius: '8px !important',
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: '0.7rem',
          },
        },
        MuiTableCell: {
          root: {
            borderBottom: `none`,
            whiteSpace: 'nowrap',

            '&:first-child': {
              paddingLeft: 32,

              '.secondary-table &': {
                paddingLeft: 10,
              },

              '.emi-table &': {
                width: 40,
                paddingLeft: 20,
              },
            },
            '&:last-child': {
              paddingRight: 32,

              '.secondary-table &': {
                paddingRight: 10,
              },
            },

            '&.MuiTableCell-head': {
              '&:first-child': {
                '.order-emi-table &': {
                  display: 'none',
                },
                '.emi-table &': {
                  display: 'none',
                },
              },
              '&.MuiTableCell-paddingCheckbox': {
                '.location-plans-table &': {
                  display: 'none',
                },
              },
            },

            '&.MuiTableCell-body': {
              '&:first-child': {
                '.emi-table &': {
                  display: 'none',
                },
              },
              '&.MuiTableCell-paddingCheckbox': {
                '.location-plans-table &': {
                  display: 'none',
                },
              },
            },
          },
        },
        MUIDataTable: {
          liveAnnounce: {
            position: 'static',
          },
          responsiveBase: {
            marginTop: 20,

            '.secondary-table &': {
              marginTop: 0,
            },

            '.invoice-list-table &': {
              marginTop: 8,
            },
          },
          tableRoot: {
            border: `1px solid transparent`,
            transition: 'all 0.2s',

            '&:focus': {
              border: `1px solid ${palette.text.light}`,
            },
          },
        },
        MUIDataTableFilter: {
          title: {
            marginLeft: 0,
          },
          gridListTile: {
            marginTop: 0,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: palette.table_head,
            fontSize: 13.5,
            lineHeight: '1.5rem',
            padding: '10px 16px',

            '& > div': {
              padding: '6px 0',
            },

            '.secondary-table &': {
              backgroundColor: 'transparent',
              borderBottom: `1px solid ${palette.text.light}`,
            },

            '.subscriber-list-table &': {
              backgroundColor: palette.table_head,
            },
          },
          fixedHeader: {
            backgroundColor: palette.table_head,
          },
          data: {
            fontSize: 'inherit',
          },
          toolButton: {
            fontSize: 'inherit',
          },
          sortLabelRoot: {
            height: 'auto',
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: 13.5,
            fontWeight: 400,
          },
          cellHide: {
            // "&$stackedCommon": {
            //   display: "none",
            //   "& + $stackedCommon": { width: "100%" },
            // },
          },
          stackedCommon: {},
        },
        MUIDataTableToolbar: {
          root: {
            padding: 0,

            '.table-widget-wrapper &': {
              padding: '24px 24px 5px 24px',
            },

            '.subscriber-list-table &': {
              padding: '10px 0 20px',

              '@media screen and (max-width: 1024px)': {
                flexDirection: 'column-reverse',
              },
            },
          },
          left: {
            '.subscriber-list-table &': {
              '@media screen and (max-width: 1024px)': {
                width: '100%',
                marginTop: '20px',
              },
            },
          },
          actions: {
            '.subscriber-list-table &': {
              minWidth: 'fit-content',

              '@media screen and (max-width: 1024px)': {
                width: '100%',
                marginTop: '20px',
              },
            },
            '& .MuiButtonBase-root:hover': {
              '.subscription-location-table &': {
                backgroundColor: 'unset',
              },
            },
            '& .MuiIconButton-root': {
              '.subscription-location-table &': {
                padding: '3px 0px',
              },
            },
          },
          '@media screen and (max-width: 959px)': {
            marginBottom: 20,
          },
        },
        MuiAccordion: {
          root: {
            background: palette.background.paper,
            '&:before': {
              display: 'none',
            },
          },
          rounded: {
            borderRadius: 12,
            '&:first-child, &:last-child': {
              borderRadius: 12,
            },
            '& + $rounded': {
              marginTop: 15,
            },
          },
        },
        MuiAccordionSummary: {
          content: {
            '&$expanded': {
              margin: '12px 0',
            },
          },
        },
        MuiAlert: {
          root: {
            fontSize: '0.9rem',
            borderRadius: 12,
            position: 'relative',
            overflow: 'hidden',
            width: 600,
            maxWidth: '100%',
          },
          outlinedError: {
            background: palette.background.error,
            '& .border-bottom': {
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '40%',
              height: 4,
              background: palette.text.errorBorder,
            },
          },
        },
        MuiSnackbar: {
          root: {
            background: palette.background.paper,
            borderRadius: 12,
          },
          anchorOriginTopCenter: {
            top: '25px !important',
          },
        },
        MuiTableRow: {
          root: {
            '&:nth-of-type(even)': {
              backgroundColor: palette.hover,
            },
            // cursor: "pointer",
          },
        },
        MuiPickersDatePickerRoot: {
          toolbarLandscape: {
            minWidth: 125,
          },
        },
      },
    }),
  );

const getTheme = (activeTheme) => {
  let palette;

  switch (activeTheme) {
    case 'default':
      palette = default_palette;
      break;
    case 'dark':
      palette = dark_palette;
      break;
    case 'green':
      palette = green_palette;
      break;
    default:
      palette = default_palette;
  }

  return generateTheme(palette);
};

export default getTheme;
