import moment from 'moment';
import * as fileSaver from 'file-saver';
import { calculatePercentageDiscount } from 'Shared/Utils';
import {
  REACT_APP_STRIPE_CHARGE_PERCENT,
  REACT_APP_STRIPE_CHARGE_FIXED_CENTS,
  REACT_APP_CREDIT_CHARGE_PERCENT,
  REACT_APP_CREDIT_CHARGE_FIXED_CENTS,
  REACT_APP_DEBIT_CHARGE_PERCENT,
  REACT_APP_DEBIT_CHARGE_FIXED_CENTS
} from 'global-constants/constants';

export const debounce = (func, wait = 400) => {
  let timeout;

  return (event) => {
    const { name, value } = event.target;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func(name, value);
    }, wait);
  };
};

export const appendURLProtocol = (url = '') => {
  const isValidURL = /^((http|https):\/\/)/.test(url);

  if (!url || isValidURL) {
    return url;
  }

  return `https://${url}`;
};

// Format to milliseconds - Used to pass in request payload
export const formatToMilliSeconds = (date) => moment.utc(date).unix();

// Format to date string - Used to pass in request payload
export const formatToDateString = (date, requiredFormat = 'yyyy-MM-DD') =>
  moment(date).format(requiredFormat);

// 1645000000 to Wed Feb 16 2022 21:53:44 GMT+0530 (India Standard Time) {} - Used to prefill forms
export const formatToDatepickerInput = (date) => moment.utc(date);

// 1645000000 to Wed Feb 16 2022 21:53:44 GMT+0530 (India Standard Time) {} - Used to prefill forms
export const formatDobToDatepicker = (date = '') =>
  moment(date).isValid() ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : null;

// Format milliseconds / MM-DD-yyyy to required format - Used to display in UI as UTC
export const formatDob = (
  date,
  currentFormat,
  requiredFormat = 'DD MMM, yyyy',
) => {
  if (typeof date === 'number') date = date * 1000;

  return moment.utc(date, currentFormat).format(requiredFormat);
};

// Format milliseconds / MM-DD-yyyy to required format - Used to display in UI
export const formatDate = (
  date,
  currentFormat,
  requiredFormat = 'DD MMM, yyyy',
) => moment.utc(date, currentFormat).local().format(requiredFormat);

// Formate from timestamp to DD-MM-yy HH:mm
export const formatDateTimeFromUnix = (
  timeStamp,
  requiredFormat = 'MM-DD-YY HH:mm',
) => moment.unix(timeStamp).format(requiredFormat);

export const convertDlinkToPreview = (url) => {
  const isGoogleDriveLink = url.includes('drive.google.com/file/d/');

  if (isGoogleDriveLink) {
    return url.replace('/view', '/preview');
  } else {
    return false;
  }
};

export const formatWithUtcOffset = (date, requiredFormat = 'DD MMM, yyyy') => {
  let formattedDate = date;
  try {
    const timeDiff = moment.parseZone(date, '').utcOffset();

    // Date 2022-10-17 14:00:00+00:00 should be treated as local slots
    // eventhough timeDiff is 0
    if (timeDiff === 0 && !date.includes('+00:00'))
      formattedDate = moment(date).format(requiredFormat);
    else
      formattedDate = moment(date).utcOffset(+timeDiff).format(requiredFormat);
  } catch (err) {
    console.log(err);
  }

  return formattedDate;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const mainCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const formatCurrency = (amount = 0) => formatter.format(amount);

export const formatMainCurrency = (amount = '') =>
  mainCurrencyFormatter.format(amount);

export const getFileExtension = (fileName) => {
  const extensionMatch = fileName.match(/\.[0-9a-z]+$/i);

  return extensionMatch ? extensionMatch[0] : '';
};

export const getImageProperties = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) reject(new Error('file not present'));

    const img = new Image();
    const binaryData = [];
    binaryData.push(file);
    img.src = URL.createObjectURL(
      new Blob(binaryData, { type: 'application/zip' }),
    );
    img.onload = () => {
      const width = img?.naturalWidth;
      const height = img?.naturalHeight;
      resolve({ width, height });
    };
    img.onerror = () =>
      reject(new Error('failed to upload the image, please try again'));
  });
};

export const formatZipcode = (inputValue) =>
  inputValue.toString().replace(/\D/g, '').slice(0, 5);

export const formatNPI = (inputValue) =>
  inputValue.toString().replace(/\D/g, '').slice(0, 10);

export const formatSSN = (inputValue) =>
  inputValue.toString().replace(/\D/g, '').slice(0, 4);

export const formatFacilityId = (inputValue) =>
  inputValue
    .toString()
    .replace(/[^0-9a-zA-Z]/g, '')
    .slice(0, 8);

export const _calculateDaysDiff = (pDate) => {
  const pastDate = moment(pDate);
  const currDate = moment();

  return currDate.diff(pastDate, 'days');
};

export const calculateStripeCharges = (
  amount,
  includeFixedFee = true,
  hasDynamicFees = false,
  cardType = '',
) => {
  if (!amount) return 0;

  if (hasDynamicFees && cardType) {
    let fixedPercent = 0;
    let fixedCents = 0;

    if (cardType === 'credit' || cardType === 'prepaid') {
      fixedPercent = REACT_APP_CREDIT_CHARGE_PERCENT;
      fixedCents = REACT_APP_CREDIT_CHARGE_FIXED_CENTS;
    } else {
      fixedPercent = REACT_APP_DEBIT_CHARGE_PERCENT;
      fixedCents = REACT_APP_DEBIT_CHARGE_FIXED_CENTS;
    }
    const finalAmt = amount + fixedCents / 100 || 0;
    return (amount && calculatePercentageDiscount(finalAmt, fixedPercent)) || 0;
  }
  const divisor = ((1 - REACT_APP_STRIPE_CHARGE_PERCENT / 100) * 100).toFixed(
    1,
  );

  let finalAmt = +((amount * 100) / divisor).toFixed(2);

  if (includeFixedFee) {
    return finalAmt + parseInt(REACT_APP_STRIPE_CHARGE_FIXED_CENTS) / 100;
  }

  return finalAmt;
};

export const downloadFile = (data, type, filename) => {
  const blob = new Blob([data], { type });

  fileSaver.saveAs(blob, filename);
};

export const getPaymentDetails = (data) => {
  const {
    card_detail = '',
    card_details = '',
    payment_method_info = '',
  } = data;

  if (!payment_method_info) {
    return card_detail || card_details;
  } else {
    return payment_method_info;
  }
};

export const getCurrentYear = () => {
  return moment().year();
};
