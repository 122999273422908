import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { PlansContext } from 'Views/Plans/context';
import {
  PLAN_DETAILS_UPDATED,
  UPDATE_PRICE_VALIDITY,
} from 'Views/Plans/context/actions';
import { SectionHeader, Select, CheckBox } from 'components';
import {
  PLAN_BILLING_FREQUENCIES,
  STRIPE_FEE_PAID_BY,
  MEMBER_TYPE,
  TIER_MEMBER_TYPE,
} from 'Views/Common/enum';

const PricingDetails = (props) => {
  const { plan = {}, planIndex } = props;

  const {
    state: { isValidPrice, highlightErrors, has_subscriptions, productInfo },
    dispatch,
  } = useContext(PlansContext);

  const { plan_details } = plan;
  const {
    billing_frequency,
    member_type,
    price_description = '',
    stripe_fee_paid_by,
    private_only = false,
    status
  } = plan_details;

  const { plan_package_type, plan_status } = productInfo;

  const isTieredPlan = plan_package_type === 'Tiered';

  const dispatchPlanDetails = (name, value) =>
    dispatch({
      type: PLAN_DETAILS_UPDATED,
      payload: {
        plan_details: { ...plan_details, [name]: value },
        planIndex,
      },
    });

  useEffect(() => {
    if (isTieredPlan) {
      dispatchPlanDetails("member_type", plan_package_type);
    }
  }, [plan_package_type]);

  useEffect(() => {
    if (!billing_frequency)
      dispatchPlanDetails(
        'billing_frequency',
        PLAN_BILLING_FREQUENCIES[0].value,
      );
  }, []);

  useEffect(() => {
    const isError = !Boolean(price_description.trim()) || !member_type;
    const isCurrentValid = isValidPrice[planIndex]?.details;

    if ((isError && !isCurrentValid) || (!isError && isCurrentValid)) return;

    dispatch({
      type: UPDATE_PRICE_VALIDITY,
      payload: {
        isValidPrice: {
          ...isValidPrice,
          [planIndex]: {
            ...isValidPrice[planIndex],
            details: !isError,
          },
        },
      },
    });
  }, [price_description, member_type]);

  const MEMBER_TYPES = isTieredPlan ? TIER_MEMBER_TYPE : MEMBER_TYPE;

  return (
    <>
      <SectionHeader
        title="Pricing Details"
        titleClasses={['font-weight-400']}
        titleVariant="h6"
        titleComponent="h6"
        rightSection={
          <>
          <FormControlLabel
            control={
              <CheckBox
                className="padding-y0"
                checked={status === 'Hidden' || plan_status === 'Hidden'}
                onChange={() =>
                  dispatchPlanDetails('status', status === 'Hidden' ? 'Active' : 'Hidden')
                }
                name="Hidden"
                disabled={plan_status === 'Hidden'}
                inputProps={{
                  'data-testid': `hide-plan-checkbox-${planIndex}`,
                }}
              />
            }
            label="Hidden"
            className="ml-10"
          />

          <FormControlLabel
            control={
              <CheckBox
                className="padding-y0"
                checked={private_only}
                onChange={() =>
                  dispatchPlanDetails('private_only', !private_only)
                }
                name="Visible In-Office Only"
                inputProps={{
                  'data-testid': `private-only-checkbox-${planIndex}`,
                }}
              />
            }
            label="Visible In-Office Only"
            className="ml-10"
          />
        </>
        }
      />

      <Grid container spacing={3} className="mg_top_24">
        <Grid item xs={12} md={6} lg={2} >
          <FormControl
            fullWidth
            disabled={isTieredPlan}
            required
            variant="outlined"
            error={Boolean(highlightErrors && !member_type)}
          >
            <InputLabel>Member Type</InputLabel>
            <Select
              id="pricing-member-type"
              name="member_type"
              label="Member Type"
              value={member_type}
              onChange={(event) =>
                dispatchPlanDetails(event.target.name, event.target.value)
              }
            >
              {MEMBER_TYPES.map((memberType, i) => (
                <MenuItem
                  key={`pricing-member-type-${i + 1}`}
                  value={memberType.key}
                  data-testid={`pricing-member-type-${i}`}
                >
                  {memberType.value}
                </MenuItem>
              ))}
            </Select>
            {Boolean(highlightErrors && !member_type) && (
              <FormHelperText>Member Type is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <FormControl
            fullWidth
            required
            variant="outlined"
            disabled={has_subscriptions}
          >
            <InputLabel htmlFor="subject-select">Billing Frequency</InputLabel>
            <Select
              id="pricing-select-frequency"
              name="billing_frequency"
              label="Billing Frequency *"
              value={billing_frequency || ''}
              onChange={(event) =>
                dispatchPlanDetails(event.target.name, event.target.value)
              }
            >
              {PLAN_BILLING_FREQUENCIES.map((frequencyItem, index) => (
                <MenuItem
                  key={`pricing-frequency-select-item-${index + 1}`}
                  value={frequencyItem.value}
                  data-testid={`pricing-frequency-select-item-${index}`}
                >
                  {frequencyItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={has_subscriptions}
          >
            <InputLabel>Stripe Fees</InputLabel>
            <Select
              id="pricing-select-fees-paidby"
              name="stripe_fee_paid_by"
              label="Stripe Fees"
              value={stripe_fee_paid_by}
              onChange={(event) =>
                dispatchPlanDetails(event.target.name, event.target.value)
              }
            >
              {Object.keys(STRIPE_FEE_PAID_BY).map((key, i) => (
                <MenuItem
                  key={key}
                  value={key}
                  data-testid={`pricing-fess-select-item-${i}`}
                >
                  {STRIPE_FEE_PAID_BY[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TextField
            fullWidth
            required
            name={'price_description'}
            id={'price_description'}
            label={'Pricing Description'}
            variant="outlined"
            value={price_description}
            error={Boolean(highlightErrors && !price_description)}
            helperText={
              Boolean(highlightErrors && !price_description) &&
              'Pricing Description is required'
            }
            onChange={(event) =>
              dispatchPlanDetails(event.target.name, event.target.value)
            }
            // disabled={has_subscriptions}
            inputProps={{
              'data-testid': 'pricing-details-description',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

PricingDetails.propTypes = {
  plan: PropTypes.object.isRequired,
  planIndex: PropTypes.number.isRequired,
  pricing_tiers_list: PropTypes.array,
};

export default PricingDetails;
