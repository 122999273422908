import axios from 'axios';
import { signOut } from 'aws-amplify/auth';
import * as Sentry from '@sentry/react';

// Constants
import { HTTP_STATUS_CODES } from '../Views/Common/enum';
import { PAGE_URLS } from 'Routes/Main/constants';

const onSuccessResponse = (response) => {
  if (response.status === 200 || response.status === 201) {
    return {
      type: 'success',
      title: HTTP_STATUS_CODES[response.status],
      message: 'Success',
      data: response.data,
    };
  } else if (response.status === 202 || response.status === 204) {
    // sent by s3 upload
    return {
      type: 'success',
      title: HTTP_STATUS_CODES[response.status],
    };
  } else {
    throw new Error({ response });
  }
};

const onErrorResponse = (error, url) => {
  Sentry.captureException(error);

  if (error.response && error.response.status === 400) {
    let msg = error.response.data.error;
    if (error.response?.data instanceof ArrayBuffer) {
      const decodedString = String.fromCharCode.apply(
        null,
        new Uint8Array(error.response?.data),
      );
      const obj = JSON.parse(decodedString);
      msg = obj?.error;
    }

    msg && Sentry.captureMessage(msg);

    return {
      type: 'error',
      title: 'Error',
      message: msg,
      data: null,
    };
  } else if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    (async () => {
      await signOut();

      window.zE('messenger', 'logoutUser');

      // window?.fcWidget?.user
      //   .clear()
      //   .then(() => {
      //     console.log('Freshchat User cleared');
      //   })
      //   .catch(() => {
      //     console.log('Freshchat User Not cleared');
      //   })
      //   .finally(() => {
      //     window?.fcWidget?.destroy();
      //   });

      localStorage.setItem('subscribiliToken', '');
      localStorage.setItem('userDetail', '');
      localStorage.setItem('tokenValidity', '');

      window.location = PAGE_URLS.LOGIN;
    })();

    return;
  } else {
    console.log(`Error in URL ${url}`, error);

    return {
      type: 'error',
      title: 'Error',
      message: 'Request Failed',
      data: null,
    };
  }
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + '/mgt',
});

client.interceptors.request.use((request) => {
  if (request.url.includes('googleapis.com')) return request;
  if (request.url.includes('s3.amazonaws.com')) return request;

  if (request.url.includes(process.env.REACT_APP_WP_URL)) {
    request.headers.Authorization = `Bearer ${localStorage.getItem(
      'subscribiliToken',
    )}`;
  } else {
    request.headers.Authorization = `Bearer ${localStorage.getItem(
      'subscribiliToken',
    )}`;
    request.headers['x-subscribili-client'] = 'provider-portal';
  }

  if (request.url.includes('/download')) {
    request.responseType = 'blob';
  }

  return request;
});

const httpService = (url) =>
  client(url)
    .then(onSuccessResponse)
    .catch((error) => onErrorResponse(error, url));

export default httpService;
