import { ReactComponent as FlashIcon } from 'assets/icons/flexpay/flash.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/flexpay/note.svg';

export const CREATE_MENU = [
  {
    title: 'Quick Invoice',
    icon: <FlashIcon />,
    key: 'quick',
  },
  {
    title: 'Standard Invoice',
    icon: <NoteIcon />,
    key: 'standard',
  },
];

export const INVOICE_STAT_CARDS = [
  {
    img: '/images/stats/invoice_paid.svg',
    alt: 'Invoice Paid Amount',
    title: 'Invoice Paid Amount',
    short_title: 'Paid',
    stat_key: 'paid',
  },
  {
    img: '/images/stats/outstanding.svg',
    alt: 'Outstanding amount',
    title: 'Outstanding amount',
    short_title: 'Outstanding',
    stat_key: 'open',
  },
  {
    img: '/images/stats/overdue.svg',
    alt: 'Overdue invoices amount',
    title: 'Overdue invoices amount',
    short_title: 'Overdue',
    stat_key: 'overdue',
  },
];

export const STATUS_LIST = [
  {
    status: 'paid',
    label: 'Paid',
  },
  {
    status: 'open',
    label: 'Open',
  },
  {
    status: 'overdue',
    label: 'Overdue',
  },
  {
    status: 'cancelled',
    label: 'Cancelled',
  },
];
