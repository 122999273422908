import moment from 'moment';
import {
  SUBSCRIPTION_TYPE,
  REACT_APP_STRIPE_CHARGE_PERCENT,
  REACT_APP_STRIPE_CHARGE_FIXED_CENTS,
  REACT_APP_CREDIT_CHARGE_PERCENT,
  REACT_APP_CREDIT_CHARGE_FIXED_CENTS,
  REACT_APP_DEBIT_CHARGE_PERCENT,
  REACT_APP_DEBIT_CHARGE_FIXED_CENTS,
  ZIPCODE_PATTERN,
  EMAIL_PATTERN,
} from 'global-constants/constants';
import * as Roles from 'global-constants/roles';
import {
  SUBSCRIBILI_USER,
  SUPER_ADMIN,
  PARTNER_ADMIN,
  REVSHARE_ADMIN,
  PARTNER_USER,
  GROUP_ADMIN,
  GROUP_USER,
  REGION_ADMIN,
  REGION_USER,
  LOCATION_ADMIN,
  LOCAL_USER,
} from 'global-constants/roles';
import {
  ALL_PARTNERS,
  ALL_GROUPS,
  ALL_LOCATIONS,
  ALL_REGIONS,
  STRIPE_ERRORS_MESSAGES,
  STRIPE_ERRORS,
} from 'Views/Common/constants';
import _ from 'lodash';
import { isWebUri } from 'valid-url';
import { formatToDateString, formatToMilliSeconds } from 'utils';
import {
  PAYMENT_TYPES_NAME,
  PLAN_PROCESS_FREQUENCIES,
  ROLES_LIST,
  STRIPE_CONTACT_MIN_AGE,
} from 'Views/Common/enum';

export const validateEmail = (email) => EMAIL_PATTERN.test(email);

const validateMobile = (phone) => /^\d{11}$/.test(phone);

const validateWebsite = (website) => isWebUri(website);

export const validateZipCode = (zipcode) => ZIPCODE_PATTERN.test(zipcode);

const validateFourDigitSSN = (ssn_last_4) => Boolean(ssn_last_4?.length === 4);

const validateNPI = (value) => /^\d{10}$/.test(value);

const validateTaxId = (value) => /^\d{9}$/.test(value);

const validateFacilityId = (value) => /^[a-zA-Z0-9]{3,8}$/.test(value);

export const isValidDate = (d) => d instanceof Date && !isNaN(d);

export const getQueryString = (data) => {
  let queryStr = '';
  let count = 0;
  for (var key in data) {
    if (data[key]) {
      queryStr += `${count > 0 ? '&' : ''}${key}=${data[key]}`;
      count++;
    }
  }
  return queryStr;
};

// subscriber config starts

export const getKeysName = (name) => {
  if (name === 'group_id') {
    return 'Group';
  } else if (name === 'location_id') {
    return 'Location';
  } else if (name === 'facility_id') {
    return 'Facility';
  } else if (name === 'partner_id') {
    return 'Partner';
  } else if (name === 'region_id') {
    return 'Region';
  } else if (name === 'plan_package_id') {
    return 'Plan Type';
  } else if (name === 'plan_id') {
    return 'Plan Frequency';
  } else if (name === 'gender') {
    return 'Gender';
  } else if (name === 'date_of_birth') {
    return 'Date of Birth';
  } else if (name === 'first_name') {
    return 'First Name';
  } else if (name === 'last_name') {
    return 'Last Name';
  } else if (name === 'full_name') {
    return 'Full Name';
  } else if (name === 'relationship') {
    return 'Relationship';
  } else {
    return name;
  }
};

const checkAllLevels = (levelId) =>
  levelId === ALL_PARTNERS.partner_id ||
  levelId === ALL_GROUPS.group_id ||
  levelId === ALL_REGIONS.region_id ||
  levelId === ALL_LOCATIONS.location_id;

const membersConfig = () => ({
  first_name: '',
  last_name: '',
  date_of_birth: null,
  gender: '',
  errorCheck: {
    first_name: { ...defaultFieldState },
    last_name: { ...defaultFieldState },
    date_of_birth: { ...defaultFieldState },
    gender: { ...defaultFieldState },
  },
});

export const getPartnerId = (groupList, group_id) => {
  for (let i in groupList) {
    let indGroup = groupList[i];
    if (indGroup.group_id === group_id) {
      return indGroup.partner_id;
    }
  }
  return null;
};

export const defaultFieldState = {
  required: true,
  isValid: true,
  value: '',
  text: '',
  error: false,
  touched: false,
};

const getEmployeeReqFields = () => ({
  group_id: { ...defaultFieldState },
  location_id: { ...defaultFieldState },
  // "partner_id": { required: true, isValid: true, value: '', text: '', error: false, touched: false },
  plan_package_id: { ...defaultFieldState },
  plan_id: { ...defaultFieldState },
  patient_name: {
    first_name: { ...defaultFieldState },
    last_name: { ...defaultFieldState },
  },
  date_of_birth: { ...defaultFieldState },
  gender: { ...defaultFieldState },
  email: { ...defaultFieldState },
  phone: { ...defaultFieldState },
  address: {
    line1: { ...defaultFieldState },
    city: { ...defaultFieldState },
    state: { ...defaultFieldState },
    zipcode: { ...defaultFieldState },
  },
  pms_id: { ...defaultFieldState, required: false },
  // emp_id: { ...defaultFieldState },
  // department: { ...defaultFieldState },
  // ben_start_date: { ...defaultFieldState },
});

export const getSubscriberReqFields = () => ({
  group_id: { ...defaultFieldState },
  location_id: { ...defaultFieldState },
  // "partner_id": { required: true, isValid: true, value: '', text: '', error: false, touched: false },
  plan_package_id: { ...defaultFieldState },
  plan_id: { ...defaultFieldState },
  patient_name: {
    first_name: { ...defaultFieldState },
    last_name: { ...defaultFieldState },
  },
  date_of_birth: { ...defaultFieldState },
  gender: { ...defaultFieldState },
  email: { ...defaultFieldState },
  phone: { ...defaultFieldState },
  address: {
    line1: { ...defaultFieldState },
    city: { ...defaultFieldState },
    state: { ...defaultFieldState },
    zipcode: { ...defaultFieldState },
  },
  pms_id: { ...defaultFieldState, required: false },
  start_date: { ...defaultFieldState, value: moment() },
});

export const responsibleReqFields = () => ({
  full_name: { ...defaultFieldState },
  email: { ...defaultFieldState },
  phone: { ...defaultFieldState },
  date_of_birth: { ...defaultFieldState },
  relationship: { ...defaultFieldState },
  address: {
    line1: { ...defaultFieldState },
    city: { ...defaultFieldState },
    state: { ...defaultFieldState },
    zipcode: { ...defaultFieldState },
  },
});

export const addressFieldsBlurred = () => ({
  line1: false,
  line2: true,
  city: false,
  state: false,
  zipcode: false,
});

export const subscriberValues = () => ({
  partner_id: '',
  group_id: '',
  location_id: '',
  region_id: '',
  plan_package_id: '',
  addons: [],
  plan_id: '',
  plan_frequency: '',
  include_signup: true,
  addons_signup: {},
  patient_name: {
    first_name: '',
    last_name: '',
  },
  date_of_birth: null,
  gender: '',
  email: '',
  phone: '',
  country_code: '+1',
  address: {
    line1: '',
    city: '',
    state: '',
    zipcode: '',
  },
  payment_method_id: '',
  pms_id: '',
  pms_display_id: '',
  start_date: moment(),
});

const employeeValues = () => ({
  partner_id: '',
  group_id: '',
  location_id: '',
  region_id: '',
  plan_package_id: '',
  addons: [],
  plan_id: '',
  plan_frequency: '',
  include_signup: true,
  addons_signup: {},
  patient_name: {
    first_name: '',
    last_name: '',
  },
  date_of_birth: null,
  gender: '',
  email: '',
  phone: '',
  country_code: '+1',
  address: {
    line1: '',
    city: '',
    state: '',
    zipcode: '',
  },
  payment_method_id: '',
  pms_id: '',
  pms_display_id: '',
  // emp_id: "",
  // department: "",
  // ben_start_date: "",
});

export const responsibleValues = () => ({
  full_name: '',
  email: '',
  relationship: '',
  phone: '',
  date_of_birth: null,
  country_code: '+1',
  address: {
    line1: '',
    city: '',
    state: '',
    zipcode: '',
  },
});

const filterPlanList = (planList) => {
  const keysArr = [];
  const finalArr = [];

  for (let i in planList) {
    if (!keysArr.length) {
      keysArr.push(planList[i].stripe_product_id);
      finalArr.push(planList[i]);
    } else {
      if (keysArr.indexOf(planList[i].stripe_product_id) === -1) {
        keysArr.push(planList[i].stripe_product_id);
        finalArr.push(planList[i]);
      }
    }
  }

  return finalArr;
};

export const getFinalPlanList = (finalPlanList, stripId) =>
  finalPlanList.filter((plan) => plan.stripe_product_id === stripId);

export const _calculateAge = (birthday) => {
  if (moment(birthday).isValid) {
    return moment().diff(birthday, 'years');
  }
  return null;
};

export const getDateError = (
  value,
  allowed_age_max,
  allowed_age_min,
  type = 'Subscriber',
) => {
  const validDate = moment(value).isValid();
  const futureDate = moment(value).isAfter();
  const exceedsMaxAge = allowed_age_max
    ? calculatePreciseAge(value).years > allowed_age_max
    : false;
  const belowMinAge = allowed_age_min
    ? moment(value).isAfter(moment().subtract(allowed_age_min, 'years'))
    : false;

  const invalidDate = moment(value).isBefore(moment('01-01-1900'));

  let message = '';
  if (!value) message = 'DOB is required';
  else if (!validDate) message = 'Invalid date format';
  else if (futureDate) message = 'Date of Birth cannot be in the future';
  else if (exceedsMaxAge)
    message = `${type}’s age must be ${allowed_age_max} or below for this plan`;
  else if (belowMinAge)
    message = `${type}’s age must be ${allowed_age_min} or above for this plan`;
  else if (invalidDate) message = 'Please enter valid date';

  return message;
};

export const getMaxMemberErrorMessage = (maxMembers) =>
  `Adding more than ${maxMembers} additional members is not allowed in this plan`;

export const calculatePreciseAge = (value) => {
  const birthDate = moment(value);
  const today = moment();

  const years = today.diff(birthDate, 'years');
  birthDate.add(years, 'years');

  const months = today.diff(birthDate, 'months');
  birthDate.add(months, 'months');

  const days = today.diff(birthDate, 'days');
  return { years, months, days };
};

export const validateStartDate = (
  value,
  allow_future_dated = false,
  frequency = 'annual_billed_monthly',
) => {
  let message = '';

  const validDate = moment(value, 'MM/DD/YYYY', true).isValid();
  const monthlyplan = frequency === 'annual_billed_monthly';

  if (!value) {
    message = 'Start Date is required';
  } else if (!validDate) {
    message = 'Invalid date format';
  } else {
    const oneMonthBefore = moment().subtract(1, 'month').add(1, 'day');
    const oneYearBefore = moment().subtract(1, 'year').add(1, 'day');
    const futureDate = moment(value).isAfter();
    const oneYearLater = moment().add(1, 'year');

    if (
      monthlyplan &&
      moment(value, 'MM/DD/YYYY').isBefore(oneMonthBefore, 'day')
    ) {
      message = 'Start Date cannot be more than 1 month in the past';
    } else if (
      !monthlyplan &&
      moment(value, 'MM/DD/YYYY').isBefore(oneYearBefore, 'day')
    ) {
      message = 'Start Date cannot be more than 1 year in the past';
    } else if (!allow_future_dated && futureDate) {
      message = 'Start Date cannot be in the future for this plan';
    } else if (
      allow_future_dated &&
      moment(value, 'MM/DD/YYYY').isAfter(oneYearLater, 'day')
    ) {
      message = 'Start Date cannot be more than 1 year in the future';
    }
  }

  return message;
};

export const getStripeDateError = (value) => {
  let dateError = getDateError(value);

  if (!dateError) {
    const dobDate = moment(value);
    const maxDate = moment().subtract(STRIPE_CONTACT_MIN_AGE, 'years');

    if (dobDate > maxDate)
      dateError = `Business representative cannot be below ${STRIPE_CONTACT_MIN_AGE} years`;
  }

  return dateError;
};

export const getTargetValue = ({ target: { type, value } }) => {
  if (type === 'number') {
    let parsedValue = Math.abs(parseFloat(+value.replace(/\D/gi, '')));

    return isNaN(parsedValue) ? 0 : parsedValue;
  }

  return value;
};

const prepareDependentsPayload = (
  additionMembers,
  planFrequency,
  addonsList,
) => {
  let dependentsPayload = [];
  if (additionMembers && additionMembers.length) {
    dependentsPayload = additionMembers.map((member) => {
      let addonPlans = [];
      if (member?.addons?.length) {
        member?.addons.forEach((indAddon) => {
          const planDetail = Utils.getPlanId(
            indAddon,
            'Dependent',
            planFrequency,
            addonsList,
          );
          const { plan_id } = planDetail || {};

          plan_id &&
            addonPlans.push({
              plan_id,
              include_signup: member?.addons_signup?.[indAddon],
            });
        });
      }

      const memberDetails = {
        plans: [
          {
            plan_id: member?.plan_id,
            include_signup: member?.include_signup,
          },
          ...addonPlans,
        ],
        patient_name: {
          first_name: member.first_name,
          last_name: member.last_name,
        },
        date_of_birth: formatToDateString(member.date_of_birth),
        gender: member.gender,
      };

      if (member?.pms_id) {
        memberDetails['pms_id'] = member?.pms_id;
      }
      if (member?.pms_display_id) {
        memberDetails['pms_display_id'] = member?.pms_display_id;
      }

      return memberDetails;
    });
  }

  return dependentsPayload;
};

export const checkIfExternalPayment = (pType) =>
  pType === PAYMENT_TYPES_NAME?.Cash || pType === PAYMENT_TYPES_NAME?.Financed;

export const getSubscriberPayload = (
  values,
  isResponsible,
  responsibleParty,
  paymentId,
  paymentType,
  additionMembers,
  addonsList,
  draftSubscription = false,
  collectRepresentativeDetails = false,
) => {
  const {
    partner_id,
    group_id,
    region_id,
    location_id,
    patient_name,
    date_of_birth,
    gender,
    pms_id,
    pms_display_id,
    facility_id = null,
    start_date,
  } = values;

  const externalPayment = checkIfExternalPayment(paymentType);

  const primaryMember = {
    plans: [
      {
        plan_id: values?.plan_id,
        include_signup: values?.include_signup,
      },
    ],
    patient_name,
    date_of_birth: formatToDateString(date_of_birth),
    gender,
  };

  if (pms_id) {
    primaryMember['pms_id'] = pms_id;
  }
  if (pms_display_id) {
    primaryMember['pms_display_id'] = pms_display_id;
  }

  let data = {
    member_type: SUBSCRIPTION_TYPE.CUSTOMER.value,
    partner_id,
    group_id,
    location_id,
    region_id,
    facility_id,
    members: [primaryMember],
  };

  if (start_date && !moment(start_date).isSame(moment(), 'day')) {
    data.start_date = formatToMilliSeconds(start_date);
  }

  if (draftSubscription) {
    data.is_draft = true;
  } else if (externalPayment || paymentId) {
    data.payment_type = paymentType;
    data.payment_method_id = paymentId;
  }

  if (values?.addons?.length) {
    values?.addons.forEach((indAddon) => {
      const planDetail = Utils.getPlanId(
        indAddon,
        'Primary',
        values?.plan_frequency,
        addonsList,
      );
      const { plan_id } = planDetail || {};

      plan_id &&
        data?.members?.[0]?.plans?.push({
          plan_id,
          include_signup: values?.addons_signup?.[indAddon],
        });
    });
  }

  if (isResponsible) {
    data = {
      ...data,
      responsible_member: {
        ...responsibleParty,
        date_of_birth: formatToDateString(responsibleParty?.date_of_birth),
      },
    };
  } else {
    data = {
      ...data,
      phone: values?.phone,
      country_code: '+1',
      email: values?.email,
      address: values?.address,
    };
  }

  if (collectRepresentativeDetails) {
    data.responsible_member = {
      ...responsibleParty,
      date_of_birth: formatToDateString(responsibleParty?.date_of_birth),
    };
  }

  const dependentsFinal = prepareDependentsPayload(
    additionMembers,
    values?.plan_frequency,
    addonsList,
  );
  data.members = [...data.members, ...dependentsFinal];

  return data;
};

const getCreateEmployeePayload = (
  values,
  isResponsible,
  responsibleParty,
  paymentId,
  paymentType,
  additionMembers,
  addonsList,
  draftSubscription = false,
) => {
  let payload = getSubscriberPayload(
    values,
    isResponsible,
    responsibleParty,
    paymentId,
    paymentType,
    additionMembers,
    addonsList,
    draftSubscription,
  );

  payload.member_type = SUBSCRIPTION_TYPE.EMPLOYEE.value;

  return payload;
};

// subscriber config end

// users

const getRoleList = () => Object.values(Roles);

const filterPlanListMY = (planList) => {
  const plans = _.cloneDeep(planList);
  const finalPlans = [];
  const stripeKeys = [];

  plans.forEach((plan) => {
    const { billing_frequency, stripe_product_id } = plan;
    const index = _.indexOf(stripeKeys, stripe_product_id);

    if (index > -1) {
      finalPlans[index] = {
        ...finalPlans[index],
        [billing_frequency]: plan,
      };
    } else {
      stripeKeys.push(stripe_product_id);

      finalPlans.push({ [billing_frequency]: plan });
    }
  });

  return finalPlans;
};

export const checkIfZeroDollarPlan = (plan) => {
  return plan?.pricing_tiers_list?.every((pricingTier) =>
    pricingTier?.pricing_tiers?.tiers?.every(
      (tier) => _.isEmpty(tier) || tier?.unit_amount === 0,
    ),
  );
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const getPhoneNumberWithCountryCode = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = /^(1|)?(\d{3})(\d{3})(\d{4})$/.exec(cleaned);

  if (match) {
    return [1, match[2], match[3], match[4]].join('');
  }

  return null;
};

export const formatTaxId = (value) => {
  const cleaned = ('' + value).replace(/\D/g, '');

  return cleaned.slice(0, 9);
};

const checkIfSuccess = (res) => Boolean(res?.type === 'success');

const checkIfPresent = (attri1, attri2) => Boolean(attri1 && attri2);

const ternaryCheck = (condition, value, defaultValue) =>
  condition ? value : defaultValue;

const getValue = (value, defaultValue) => value || defaultValue;

const getRole = (role) => {
  let indRole = role.split(':');
  switch (indRole[1]) {
    case Roles.PARTNER_ADMIN.key:
      return Roles.PARTNER_ADMIN.value;
    case Roles.PARTNER_USER.key:
      return Roles.PARTNER_USER.value;
    case Roles.GROUP_ADMIN.key:
      return Roles.GROUP_ADMIN.value;
    case Roles.GROUP_USER.key:
      return Roles.GROUP_USER.value;
    case Roles.REGION_ADMIN.key:
      return Roles.REGION_ADMIN.value;
    case Roles.REGION_USER.key:
      return Roles.REGION_USER.value;
    case Roles.SUPER_ADMIN.key:
      return Roles.SUPER_ADMIN.value;
    case Roles.SUBSCRIBILI_USER.key:
      return Roles.SUBSCRIBILI_USER.value;
    case Roles.LOCATION_ADMIN.key:
      return Roles.LOCATION_ADMIN.value;
    case Roles.LOCAL_USER.key:
      return Roles.LOCAL_USER.value;
    default:
      return 'Unknown';
  }
};

const formatUserRole = (role) => {
  if (!role) {
    return '';
  }

  if (role.indexOf('r:') !== -1) {
    let splitRole = role.split(':');
    return ROLES_LIST[splitRole[1]] || splitRole[1];
  }
};

const getPhone = (phone) => {
  if (phone[0] === '1') {
    return phone.substring(1, phone.length);
  }
  return phone;
};

export const checkIfInternalUser = (user) => {
  const rolesList = [SUPER_ADMIN, SUBSCRIBILI_USER];

  return rolesList?.some((role) => user?.['custom:role'].includes(role.key));
};

export const isSubscribiliUser = (user) => {
  const subscribiliRole = SUBSCRIBILI_USER;
  return user?.['custom:role'].includes(subscribiliRole.key);
};

export const isSuperadminUser = (user) => {
  const superadminRole = SUPER_ADMIN;
  return user?.['custom:role'].includes(superadminRole.key);
};

export const isRevshareAdmin = (user) => {
  const rolesList = [REVSHARE_ADMIN];

  return rolesList?.some((role) => user?.['custom:role'].includes(role.key));
};

export const isPartnerUser = (user) => {
  const rolesList = [PARTNER_ADMIN, PARTNER_USER];

  return rolesList?.some((role) => user?.['custom:role'].includes(role.key));
};

export const isGroupUser = (user) => {
  const rolesList = [GROUP_ADMIN, GROUP_USER];

  return rolesList?.some((role) => user?.['custom:role'].includes(role.key));
};

export const isRegionUser = (user) => {
  const rolesList = [REGION_ADMIN, REGION_USER];

  return rolesList?.some((role) => user?.['custom:role'].includes(role.key));
};

export const isLocationUser = (user) => {
  const rolesList = [LOCATION_ADMIN, LOCAL_USER];

  return rolesList?.some((role) => user?.['custom:role'].includes(role.key));
};

export const calculatePercentageDiscount = (fees, fixedPercent) => {
  const discountRate = fixedPercent / 100;
  const totalAmount = fees / (1 - discountRate);
  return parseFloat(totalAmount.toFixed(2));
};

export const calculateDiscount = (
  finalAmount,
  processing_charges,
  annualCost,
  couponDetail,
  skipFixedValue = false,
  hasDynamicFees,
  cardType,
  hasExistingPayment = false,
) => {
  let fixedPercent = REACT_APP_STRIPE_CHARGE_PERCENT;
  let fixedCents = 0;
  if (hasDynamicFees) {
    if (!cardType) {
      fixedPercent = REACT_APP_STRIPE_CHARGE_PERCENT;
      fixedCents = REACT_APP_CREDIT_CHARGE_FIXED_CENTS;
    } else if (cardType === 'credit' || cardType === 'prepaid') {
      fixedPercent = REACT_APP_CREDIT_CHARGE_PERCENT;
      fixedCents = REACT_APP_CREDIT_CHARGE_FIXED_CENTS;
    } else {
      fixedPercent = REACT_APP_DEBIT_CHARGE_PERCENT;
      fixedCents = REACT_APP_DEBIT_CHARGE_FIXED_CENTS;
    }
  }
  let discountAmount = 0;
  let fixedCharge =
    !processing_charges || skipFixedValue || couponDetail.percent_off === 100
      ? 0
      : parseInt(REACT_APP_STRIPE_CHARGE_FIXED_CENTS) / 100;

  if (finalAmount) {
    if (couponDetail?.percent_off) {
      if (hasDynamicFees) {
        discountAmount = (finalAmount * couponDetail.percent_off) / 100;
      } else {
        discountAmount =
          ((finalAmount + processing_charges + fixedCharge) *
            couponDetail.percent_off) /
          100;
      }
    } else if (couponDetail?.amount_off) {
      discountAmount = parseFloat(couponDetail.amount_off / 100);
    }
  }
  let finalizedAmt = finalAmount - discountAmount;
  let dynamicFee = (finalizedAmt && finalizedAmt + fixedCents / 100) || 0;
  let dynamicProcessingCharges = 0;
  let processingCharges = processing_charges + fixedCharge || 0;
  if (hasDynamicFees) {
    dynamicProcessingCharges =
      calculatePercentageDiscount(dynamicFee, fixedPercent) - finalizedAmt;
    const customProcessingCharges =
      cardType || hasExistingPayment ? dynamicProcessingCharges : 0;
    processingCharges = processing_charges ? customProcessingCharges : 0;
  }

  return {
    discount: discountAmount,
    amountDue: finalAmount,
    processingFee: processingCharges,
    annualCost,
  };
};

export const getPlanPackageType = (selPlan) => {
  if (selPlan?.pricing_tiers_list?.[0]?.plan_type === 'Tiered') return 'Tiered';

  return selPlan?.plan_package_type || 'Base';
};

export const getPlanType = (plan_package_id, planArray = []) => {
  const selPlan =
    planArray?.find((item) => item.plan_package_id === plan_package_id) || {};

  return selPlan?.pricing_tiers_list?.[0]?.plan_type || '';
};

export const getPlanId = (...args) => {
  const [plan_package_id, , , , isTieredPlan = false] = args;

  if (!plan_package_id) return;

  if (isTieredPlan) {
    const planDetails = getTieredPlanId(...args);

    return planDetails;
  } else {
    const planDetails = getBasePlanId(...args);

    return planDetails;
  }
};

const getBasePlanId = (plan_package_id, memberType, frequency, planArray) => {
  const selPlan = planArray.find(
    (item) => item.plan_package_id === plan_package_id,
  );
  const tiers = selPlan?.pricing_tiers_list.filter(
    (item) =>
      item?.pricing_tiers?.billing_frequency ===
      PLAN_PROCESS_FREQUENCIES[frequency],
  );
  const general = tiers?.filter((item) => item.plan_type === 'General') || [];

  if (general.length) {
    return {
      plan_name: selPlan?.plan_name,
      plan_type: 'General',
      plan_id: general?.[0]?.plan_id,
      selectedTier: general?.[0]?.pricing_tiers,
    };
  } else {
    const selPriceTier =
      tiers?.find((item) => item.plan_type === memberType) || {};
    return {
      plan_name: selPlan?.plan_name,
      plan_type: memberType,
      plan_id: selPriceTier?.plan_id,
      selectedTier: selPriceTier?.pricing_tiers,
    };
  }
};

export const getTieredPlanId = (
  plan_package_id,
  _memberType,
  frequency,
  planArray,
  _isTieredPlan,
  memberId,
) => {
  if (!plan_package_id) return;

  const selPlan = planArray.find(
    (item) => item.plan_package_id === plan_package_id,
  );
  const tiers = selPlan?.pricing_tiers_list.filter(
    (item) =>
      item?.pricing_tiers?.billing_frequency ===
      PLAN_PROCESS_FREQUENCIES[frequency],
  );

  const selPriceTier = tiers?.find(
    (item) =>
      memberId >= Number(item?.member_from) && memberId <= item?.member_to,
  );

  return {
    plan_name: selPlan?.plan_name,
    plan_type: 'Tiered',
    plan_id: selPriceTier?.plan_id,
    selectedTier: selPriceTier?.pricing_tiers,
  };
};

export const showBookAppointments = (pmsVendor = []) => {
  const vendorsList = ['athena', 'elation health'];

  return !pmsVendor.some((vendor) => vendorsList.includes(vendor));
};

export const calculateDynamicMemberCharges = (
  selectedPriceTier = {},
  includeSignup = true,
  zeroProcessingFee = false,
  cardType = 'credit',
) => {
  const { tiers = [] } = selectedPriceTier;
  const {
    unit_amount = 0,
    upfront_amount = 0,
    signup_amount = 0,
  } = tiers[0] || {};
  let fixedCents = 0;

  if (cardType === 'credit' || cardType === 'prepaid') {
    fixedCents = REACT_APP_CREDIT_CHARGE_FIXED_CENTS;
  } else if (cardType === 'debit') {
    fixedCents = REACT_APP_DEBIT_CHARGE_FIXED_CENTS;
  } else {
    fixedCents = REACT_APP_STRIPE_CHARGE_FIXED_CENTS;
  }

  // Primary Member Charges
  const primary_mem_amount = unit_amount / 100;
  const primary_up_fees = upfront_amount / 100;
  let primary_signup_fees = 0;

  if (includeSignup) {
    primary_signup_fees = signup_amount / 100;
  }

  // Primary Member Processing Fees
  const primary_recurring_pro_fee = primary_mem_amount;
  const primary_upfront_pro_fee = primary_up_fees;
  const primary_signup_pro_fee = primary_signup_fees;
  const totalProcessingFee = zeroProcessingFee
    ? 0
    : primary_recurring_pro_fee +
      primary_upfront_pro_fee +
      primary_signup_pro_fee +
      parseInt(fixedCents) / 100;
  const primaryMemberCost =
    primary_mem_amount + primary_up_fees + primary_signup_fees;

  return {
    primary_mem_amount,
    primary_up_fees,
    primary_signup_fees,
    totalProcessingFee,
    primaryMemberCost,
  };
};

export const calculateMemberCharges = (
  selectedPriceTier = {},
  includeSignup = true,
  zeroProcessingFee = false,
) => {
  const { tiers = [] } = selectedPriceTier;
  const {
    unit_amount = 0,
    unit_charge_amount = 0,
    upfront_amount = 0,
    upfront_charge_amount = 0,
    signup_amount = 0,
    signup_charge_amount = 0,
  } = tiers[0] || {};

  // Primary Member Charges
  const primary_mem_amount = unit_amount / 100;
  const primary_mem_charge = (unit_charge_amount || unit_amount) / 100;
  const primary_up_fees = upfront_amount / 100;
  const primary_up_charge = (upfront_charge_amount || upfront_amount) / 100;

  let primary_signup_fees = 0;
  let primary_signup_charge = 0;

  if (includeSignup) {
    primary_signup_fees = signup_amount / 100;
    primary_signup_charge = (signup_charge_amount || signup_amount) / 100;
  }

  // Primary Member Processing Fees
  const primary_recurring_pro_fee = primary_mem_charge - primary_mem_amount;
  const primary_upfront_pro_fee = primary_up_charge - primary_up_fees;
  const primary_signup_pro_fee = primary_signup_charge - primary_signup_fees;
  const totalProcessingFee = zeroProcessingFee
    ? 0
    : primary_recurring_pro_fee +
      primary_upfront_pro_fee +
      primary_signup_pro_fee;
  const primaryMemberCost =
    primary_mem_amount + primary_up_fees + primary_signup_fees;

  return {
    primary_mem_amount,
    primary_up_fees,
    primary_signup_fees,
    totalProcessingFee,
    primaryMemberCost,
  };
};

export const doesPlanHasActivationCost = (selectedPriceTier = {}) => {
  const { tiers = [] } = selectedPriceTier;
  const { signup_amount = 0, signup_charge_amount = 0 } = tiers[0] || {};

  return signup_amount > 0 || signup_charge_amount > 0;
};

export const getDecimalValue = (value) => {
  let replacedValue = value.replace(/[^0-9.]/g, '').replace(/\.{2,}/g, '.');
  let lastIndex;

  // Replace multiple dots
  while (
    replacedValue.indexOf('.') !== (lastIndex = replacedValue.lastIndexOf('.'))
  ) {
    replacedValue =
      replacedValue.substring(0, lastIndex) +
      replacedValue.substring(lastIndex + 1);
  }

  return replacedValue;
};

export const processPlanByFrequency = (plans = []) => {
  const groupedPlans = {};
  const getFrequencyKey = (selFreq) =>
    Object.keys(PLAN_PROCESS_FREQUENCIES).find(
      (key) => PLAN_PROCESS_FREQUENCIES[key] === selFreq,
    );

  plans.length &&
    plans.forEach((plan) => {
      plan?.pricing_tiers_list?.forEach((pTier) => {
        const plan_frequency = getFrequencyKey(
          pTier?.pricing_tiers?.billing_frequency,
        );
        const planExists =
          groupedPlans?.[plan_frequency]?.length &&
          groupedPlans[plan_frequency].find(
            (gPlan) => gPlan.plan_package_id === plan.plan_package_id,
          );

        if (!planExists) {
          !groupedPlans?.[plan_frequency] &&
            (groupedPlans[plan_frequency] = []);
          groupedPlans[plan_frequency].push(plan);
        }
      });
    });

  return groupedPlans;
};

const processHiddenPlans = (allPlans, targetBillingFrequency) => {
  const uniquePlanIds = new Set();
  const uniquePlans = [];

  allPlans?.forEach((plan) => {
    plan?.pricing_tiers_list?.forEach((pricingTier) => {
      const currentBillingFrequency =
        pricingTier?.pricing_tiers?.billing_frequency;

      if (currentBillingFrequency === targetBillingFrequency) {
        const planStatus =
          pricingTier?.status === 'Active' ? 'Active' : 'Hidden';
        const planId = plan.plan_package_id;

        if (!uniquePlanIds.has(planId)) {
          uniquePlanIds.add(planId);
          uniquePlans.push({ ...plan, status: planStatus });
        }
      }
    });
  });

  return uniquePlans;
};

const doesStripeFeesPaidByProvider = (planList, planId) => {
  const pricingTiersList =
    planList.map((plans) => plans?.pricing_tiers_list).flat(1) || [];
  let selectedSubscriberPlan = pricingTiersList?.find(
    (plan) => plan?.plan_id == planId,
  );
  const { stripe_fee_paid_by = '' } =
    selectedSubscriberPlan?.pricing_tiers || {};

  return Boolean(stripe_fee_paid_by === 'provider');
};

export const getPaymentDisplay = (paymentList = [], name = '') => {
  const { display = name } =
    paymentList.find((indPayment) => indPayment?.name === name) || {};

  return display;
};

const checkForSpellCorrection = (spellCorrectedFlag, spellCorrected) =>
  spellCorrectedFlag || spellCorrected || false;
const checkForReplacedComponents = (replacedFlag, replaced) =>
  replacedFlag || replaced || false;
const checkForUnexpected = (
  unexpectedFlag,
  unexpected,
  confirmationLevelInfo,
) => {
  const confirmationLevel = ['CONFIRMED'];
  return unexpected || confirmationLevel.includes(confirmationLevelInfo)
    ? false
    : unexpectedFlag || false;
};

const getAddressFields = (addressComponents) => {
  const requiredAddressFields = [
    'street_number', //line 1
    'route', // line 1
    'neighborhood', //line 2
    'locality', // city
    'administrative_area_level_1', // state
    'postal_code', // zipCode
  ];
  let results = { line1: '', line2: '', city: '', state: '', zipcode: '' };
  let spellCorrected = false;
  let unexpected = false;
  let replaced = false;
  addressComponents.forEach((elem) => {
    const key = elem?.types?.[0] || elem?.componentType || '';
    if (requiredAddressFields.includes(key)) {
      switch (key) {
        case 'street_number':
          results = {
            ...results,
            line1: elem?.long_name || elem?.componentName?.text || '',
          };
          spellCorrected = checkForSpellCorrection(
            elem?.spellCorrected,
            spellCorrected,
          );
          replaced = checkForReplacedComponents(elem?.replaced, replaced);
          unexpected = checkForUnexpected(
            elem?.unexpected,
            unexpected,
            elem?.confirmationLevel,
          );
          break;
        case 'route':
          results = {
            ...results,
            line1:
              results?.line1 +
              ` ${elem?.long_name || elem?.componentName?.text || ''}`,
          };
          spellCorrected = checkForSpellCorrection(
            elem?.spellCorrected,
            spellCorrected,
          );
          replaced = checkForReplacedComponents(elem?.replaced, replaced);
          unexpected = checkForUnexpected(
            elem?.unexpected,
            unexpected,
            elem?.confirmationLevel,
          );
          break;
        case 'locality':
          results = {
            ...results,
            city: elem?.long_name || elem?.componentName?.text || '',
          };
          spellCorrected = checkForSpellCorrection(
            elem?.spellCorrected,
            spellCorrected,
          );
          replaced = checkForReplacedComponents(elem?.replaced, replaced);
          unexpected = checkForUnexpected(
            elem?.unexpected,
            unexpected,
            elem?.confirmationLevel,
          );
          break;
        case 'administrative_area_level_1':
          results = {
            ...results,
            state: elem?.short_name || elem?.componentName?.text || '',
          };
          spellCorrected = checkForSpellCorrection(
            elem?.spellCorrected,
            spellCorrected,
          );
          replaced = checkForReplacedComponents(elem?.replaced, replaced);
          unexpected = checkForUnexpected(
            elem?.unexpected,
            unexpected,
            elem?.confirmationLevel,
          );
          break;
        case 'neighborhood':
          results = {
            ...results,
            line2: elem?.long_name || elem?.componentName?.text || '',
          };
          spellCorrected = checkForSpellCorrection(
            elem?.spellCorrected,
            spellCorrected,
          );
          replaced = checkForReplacedComponents(elem?.replaced, replaced);
          unexpected = checkForUnexpected(
            elem?.unexpected,
            unexpected,
            elem?.confirmationLevel,
          );
          break;
        case 'postal_code':
          results = {
            ...results,
            zipcode: elem?.long_name || elem?.componentName?.text || '',
          };
          spellCorrected = checkForSpellCorrection(
            elem?.spellCorrected,
            spellCorrected,
          );
          replaced = checkForReplacedComponents(elem?.replaced, replaced);
          unexpected = checkForUnexpected(
            elem?.unexpected,
            unexpected,
            elem?.confirmationLevel,
          );
          break;
        default:
          results = { ...results };
          spellCorrected = false;
          unexpected = false;
          replaced = false;
      }
    }
  });
  return { results, spellCorrected, unexpected, replaced };
};

const checkAddressValidity = (
  validityVerdict,
  spellCorrected = false,
  unexpected = false,
  replaced = false,
  uspsData = {},
) => {
  let dpvConfirmation = uspsData?.dpvConfirmation || 'N';
  const acceptedValidationGranularity = ['PREMISE', 'SUB_PREMISE'];
  const validationGranularity =
    validityVerdict?.validationGranularity || 'OTHERS';
  let verdict;
  if (spellCorrected || unexpected || replaced)
    return { isValidAddress: false, validationGranularity };

  if (acceptedValidationGranularity.includes(validationGranularity))
    verdict = { isValidAddress: true, validationGranularity };
  else {
    switch (dpvConfirmation) {
      case '':
      case 'N':
      case 'D':
        verdict = { isValidAddress: false, validationGranularity };
        break;
      case 'S':
      case 'Y':
        verdict = { isValidAddress: true, validationGranularity };
        break;

      default:
        verdict = { isValidAddress: false, validationGranularity };
    }
  }
  return verdict;
};

const getSortedPricingTiers = (pricing_tiers_list) => {
  pricing_tiers_list.sort((a, b) => {
    if (
      a?.pricing_tiers?.billing_frequency ===
      b?.pricing_tiers?.billing_frequency
    ) {
      return a.member_from - b.member_from;
    }

    return a?.pricing_tiers?.billing_frequency.localeCompare(
      b?.pricing_tiers?.billing_frequency,
    );
  });

  return pricing_tiers_list;
};

const getStripeError = (errorData) => {
  let message;
  switch (errorData?.decline_code || errorData?.code) {
    case STRIPE_ERRORS.CHARGE_DECLINED:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED;
      break;
    case STRIPE_ERRORS.CHARGE_DECLINED_INSUFFICIENT_FUNDS:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED_INSUFFICIENT_FUNDS;
      break;
    case STRIPE_ERRORS.CHARGE_DECLINED_LOST_CARD:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED_LOST_CARD;
      break;
    case STRIPE_ERRORS.CHARGE_DECLINED_EXPIRED_CARD:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED_EXPIRED_CARD;
      break;
    case STRIPE_ERRORS.CHARGE_DECLINED_STOLEN_CARD:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED_STOLEN_CARD;
      break;
    case STRIPE_ERRORS.CHARGE_DECLINED_PROCESSING_ERROR:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED_PROCESSING_ERROR;
      break;
    default:
      message = STRIPE_ERRORS_MESSAGES.CHARGE_DECLINED;
      break;
  }

  return message;
};

/**
 * Filters the given list of plans to return only those with a primary cost.
 *
 * A plan is considered to have a primary cost if it has a pricing tier
 * where `member_from` and `member_to` are both 1, and the status is 'Active'.
 *
 * @param {Array} plansList - The list of plans to filter.
 * @param {string} selectedFrequency - The selected frequency (not used in the current implementation).
 * @returns {Array} - The filtered list of plans with a primary cost.
 */
export const getPlansWithPrimaryCost = (plansList, selectedFrequency) => {
  const planList =
    plansList?.filter((plan) =>
      plan?.pricing_tiers_list?.some(
        (tiersList) =>
          tiersList?.member_from === 1 &&
          tiersList?.member_to === 1 &&
          tiersList?.status === 'Active',
      ),
    ) || [];
  return planList;
};

/**
 * Filters and returns the active frequency tiers from the provided pricing tiers list.
 *
 * @param {Array} [pricingTiersList=[]] - The list of pricing tiers.
 * @returns {Array} - The filtered list of active frequency tiers.
 */
export const getActiveBillingFrequencyTiers = (pricingTiersList = []) => {
  const isTieredPlan = pricingTiersList?.[0]?.plan_type === 'Tiered';

  const activeBillingFrequencyTiers =
    pricingTiersList?.filter(
      (currentTier, index, tiersArray) =>
        index ===
        tiersArray.findIndex((tier) => {
          const hasPrimaryCost = isTieredPlan
            ? tier?.member_from === 1 &&
              tier?.member_to === 1 &&
              tier?.status === 'Active'
            : true;

          return (
            tier.pricing_tiers?.billing_frequency ===
              currentTier.pricing_tiers?.billing_frequency && hasPrimaryCost
          );
        }),
    ) || [];

  return activeBillingFrequencyTiers;
};

const Utils = {
  validateEmail,
  validateMobile,
  validateZipCode,
  validateWebsite,
  validateFourDigitSSN,
  validateNPI,
  validateTaxId,
  validateFacilityId,
  isValidDate,
  getQueryString,
  getKeysName,
  checkAllLevels,
  membersConfig,
  getPartnerId,
  defaultFieldState,
  getEmployeeReqFields,
  getSubscriberReqFields,
  responsibleReqFields,
  subscriberValues,
  employeeValues,
  responsibleValues,
  filterPlanList,
  getFinalPlanList,
  _calculateAge,
  getDateError,
  getStripeDateError,
  getTargetValue,
  checkIfExternalPayment,
  getSubscriberPayload,
  getCreateEmployeePayload,
  getRoleList,
  formatPhoneNumber,
  filterPlanListMY,
  checkIfSuccess,
  checkIfPresent,
  ternaryCheck,
  getValue,
  getRole,
  formatUserRole,
  getPhone,
  checkIfInternalUser,
  calculateDiscount,
  getPlanType,
  getPlanPackageType,
  getPlanId,
  showBookAppointments,
  calculateMemberCharges,
  isSubscribiliUser,
  isSuperadminUser,
  isPartnerUser,
  isRevshareAdmin,
  isGroupUser,
  isRegionUser,
  isLocationUser,
  doesPlanHasActivationCost,
  processPlanByFrequency,
  processHiddenPlans,
  doesStripeFeesPaidByProvider,
  getAddressFields,
  checkAddressValidity,
  addressFieldsBlurred,
  getSortedPricingTiers,
  getStripeError,
  getPlansWithPrimaryCost,
};

export default Utils;
