import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import styles from './CommonDialog.module.scss';
import { Close } from '@material-ui/icons';

const CommonDialog = (props) => {
  const {
    open,
    handleClose,
    className,
    dialogTitle,
    titleImage = '',
    showCloseIcon = true,
    titleClass = '',
    children,
    secondaryBtnTxt,
    primaryBtnTxt,
    maxWidth,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    disabledPrimaryButton,
    disableSecondaryButton = false,
    footerContent,
    notes,
    primaryButtonProps,
    secondaryButtonProps,
    isLoading = false,
    isSecondaryBtnLoading = false,
    primaryButtonStyles,
    secondaryButtonStyles,
    paperClass = '',
    backdropClass = '',
    contentClass = '',
    noPadding = false,
    centerButton = false,
    disableForm = false,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth={maxWidth}
      className={clsx(className, (isLoading || disableForm) && 'disable-form')}
      PaperProps={{
        className: paperClass,
      }}
      BackdropProps={{
        className: backdropClass,
      }}
    >
      {dialogTitle && (
        <DialogTitle
          id="dialog-title"
          className={clsx(
            styles.dialogTitle,
            'd-flex align-center justify-content-between',
            titleClass,
          )}
          disableTypography
        >
          {dialogTitle}
          {titleImage}
          {showCloseIcon && (
            <IconButton
              size="small"
              onClick={handleClose}
              data-testid="dialog-close-button"
            >
              <Close className={styles.closeIcon} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        className={clsx(
          contentClass,
          styles.dialogContent,
          noPadding && styles.noPadding,
        )}
      >
        {children}
      </DialogContent>

      {Boolean(footerContent || notes || secondaryBtnTxt || primaryBtnTxt) && (
        <DialogActions
          className={clsx(
            styles.dialogActions,
            (footerContent || notes) && 'justify-content-between',
            centerButton && styles.alignCenter,
          )}
        >
          {footerContent && (
            <div className="align-self-start mr-auto">{footerContent}</div>
          )}

          {notes && (
            <div className="align-self-start mr-auto">
              <Typography>Note</Typography>
              <Typography color="textPrimary">{notes}</Typography>
            </div>
          )}

          <>
            {secondaryBtnTxt && (
              <Button
                onClick={handleSecondaryButtonClick || handleClose}
                color="secondary"
                variant="outlined"
                size="large"
                disabled={disableSecondaryButton}
                style={secondaryButtonStyles}
                data-testid={`dialog-${secondaryBtnTxt
                  .replace(/ /g, '-')
                  .toLowerCase()}`}
                {...secondaryButtonProps}
              >
                {isSecondaryBtnLoading && (
                  <CircularProgress size={20} className={styles.spinner} />
                )}
                {!isSecondaryBtnLoading && secondaryBtnTxt}
              </Button>
            )}

            {primaryBtnTxt && (
              <Button
                onClick={handlePrimaryButtonClick}
                color="secondary"
                variant="contained"
                size="large"
                disabled={disabledPrimaryButton}
                className="ml-10"
                style={primaryButtonStyles}
                data-testid={`dialog-${primaryBtnTxt
                  .replace(/ /g, '-')
                  .toLowerCase()}`}
                {...primaryButtonProps}
              >
                {isLoading && (
                  <CircularProgress
                    size={20}
                    className={clsx('color_white', styles.spinner)}
                  />
                )}
                {!isLoading && primaryBtnTxt}
              </Button>
            )}
          </>
        </DialogActions>
      )}
    </Dialog>
  );
};

CommonDialog.defaultProps = {
  maxWidth: 'md',
  isPrimaryButtonValid: true,
};

CommonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  secondaryBtnTxt: PropTypes.string,
  primaryBtnTxt: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePrimaryButtonClick: PropTypes.func,
  disabledPrimaryButton: PropTypes.bool,
  handleSecondaryButtonClick: PropTypes.func,
  disableSecondaryButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  isPrimaryButtonValid: PropTypes.bool,
  footerContent: PropTypes.node,
  disableForm: PropTypes.bool,
  className: PropTypes.string,
  titleClass: PropTypes.string,
  titleImage: PropTypes.node,
  showCloseIcon: PropTypes.bool,
  notes: PropTypes.string,
  primaryButtonProps: PropTypes.object,
  secondaryButtonProps: PropTypes.object,
  isLoading: PropTypes.bool,
  isSecondaryBtnLoading: PropTypes.bool,
  primaryButtonStyles: PropTypes.object,
  secondaryButtonStyles: PropTypes.object,
  paperClass: PropTypes.string,
  backdropClass: PropTypes.string,
  contentClass: PropTypes.string,
  noPadding: PropTypes.bool,
  centerButton: PropTypes.bool,
};

export default CommonDialog;
