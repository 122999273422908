export const getUniquePlans = (pricingTierList) => {
  const billingFrequencyMap = new Map();
  const isTieredPlan = pricingTierList?.[0]?.plan_type === 'Tiered';

  pricingTierList.forEach((pTier) => {
    if (isTieredPlan) {
      const isPrimaryActive = planHasActiveFrequencyPricing(
        pricingTierList,
        pTier.pricing_tiers.billing_frequency,
      );

      isPrimaryActive &&
        billingFrequencyMap.set(pTier.pricing_tiers.billing_frequency, pTier);
    } else {
      billingFrequencyMap.set(pTier.pricing_tiers.billing_frequency, pTier);
    }
  });

  return Array.from(billingFrequencyMap.values());
};

export const getPlans = (pricingTierList) => {
  const billingFrequencyMap = new Map();

  pricingTierList.forEach((pTier) => {
    billingFrequencyMap.set(pTier.pricing_tiers.billing_frequency, pTier);
  });
  return Array.from(billingFrequencyMap.values());
};

export const planHasActiveFrequencyPricing = (
  pricingTierList,
  billingFrequency,
  skipFilter = false
) => {
  if (skipFilter) return pricingTierList;
  const hasActivePrimaryTier = pricingTierList.some(
    (tier) =>
      tier.pricing_tiers.billing_frequency === billingFrequency &&
      tier.member_from === 1 &&
      tier.member_to === 1 &&
      tier.status === 'Active',
  );

  return hasActivePrimaryTier;
};
