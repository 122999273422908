import React from 'react';
import {
  CircularProgress,
  Select as MuiSelect,
  SelectProps,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const Select = (props) => {
  const { loading = false, inputProps, ...restProps } = props;

  return (
    <MuiSelect
      {...restProps}
      IconComponent={loading ? CircularProgressIcon : KeyboardArrowDownIcon}
      inputProps={{
        ...inputProps,
        'data-testid':
          props?.id ||
          props?.name ||
          props?.label?.replace(/ /g, '-').toLowerCase() ||
          'select-dropdown',
      }}
    />
  );
};

const CircularProgressIcon = (props) => {
  return <CircularProgress size={20} style={{ marginRight: 6 }} {...props} />;
};

export default Select;
